import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import jQuery from 'jquery';

import * as Utils from '../lib/utils';
import CONFIG from '../config';

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const modalBulkStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

// const data = [];
//nested data is ok, see accessorKeys in ColumnDef below  
const PromotionAdvancements = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });

    // const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear());

    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalAction, setModalAction] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [jobAdvacementId, setJobAdvacementId] = useState("");
    const [modalJobTitle, setModalJobTitle] = useState("");
    const [modalPromotionTitle, setModalPromotionTitle] = useState("");

    const [modalBulkIsOpen, setBulkIsOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const urlParams = Utils.getParams();
    const groupId = urlParams.group_id;
    const selectedYear = urlParams.year;

    let subtitle;
    
    
    function openBulkModal() {
      setBulkIsOpen(true);
    }     
    function afterOpenBulkModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeBulkModal() {
      setBulkIsOpen(false);
    }


    function openModal() {
      setModalText();
      setIsOpen(true);
    } 
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeModal() {
      setIsOpen(false);
    }


    function setModalText() {

      let header = "Add a job advancement.";
      let body = <div>
        <div>
          <span className="jobTitleLabel">Job Title: </span> 
          <input 
            type="text" 
            name="job_title" 
            id="job_title" 
            defaultValue={modalJobTitle} 
            onChange={evt => setModalJobTitle(evt.target.value)}  
          />
        </div>
        <div>
          <span className="jobTitleLabel">Promotion Title: </span> 
          <input 
            type="text" 
            name="promotion_title" 
            id="promotion_title" 
            defaultValue={modalPromotionTitle} 
            onChange={evt => setModalPromotionTitle(evt.target.value)} 
          />
        </div>
      </div>;

      setModalTitle(header);
      setModalBody(body);
    
      return false;
    }


    const handleTableData = async () =>{
      const jsonResponse = await Utils.getData(
        ('/api/promotion_advancement'), 
        instance, 
        account
      );
      
      setTableData(jsonResponse);
      setRowCount(jsonResponse.length);
    }

    const fetchData = async () => {
      if (!tableData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {

        handleTableData();

      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });

    //should be memoized or stable
    const columns = useMemo(
      () => [
          {
            accessorKey: 'delete_promotion',
            header: 'Delete Promotion',
            Cell: ({ cell, row }) => {
              return <div><a href="#" onClick={(event)=>{
                event.preventDefault();
                // console.log("EVENT", event, cell, row );
                if(window.confirm("Are you sure you would like to delete this promotion type?")){
                  deleteJobAdvancement(cell.row.original.id);
                }
                return false;
              }}>Delete</a></div>
            },
            enableEditing: false,
            columnDefType: 'display',
            enablePinning: false
        },         
        {
          accessorKey: 'job_title',
          header: 'Job Title',
          enableEditing: false
        },
        {
          accessorKey: 'promotion_title',
          header: 'Promotion Title',
          enableEditing: true
        }
      ],
      [],
    );


    //saves the group to the DB and updates the client view
    const saveJobAdvancement = () => {
      Utils.postData( "/api/promotion_advancement", {
        job_title : modalJobTitle,
        promotion_title : modalPromotionTitle
      }, instance, account ).then((data) => { 
        handleTableData();
        closeModal();
        return false;
      });
      return false;
    }


    const deleteJobAdvancement = (id) => {
        Utils.deleteData(
          "/api/promotion_advancement/" + id, 
          instance, 
          account
        ).then((data) => {
          alert(data.message);

          if(data.result === "SUCCESS"){
            fetchData();
          }
        });
    }


    const uploadJobAdvancement = () => {
      const formData = new FormData();
      formData.append('file', selectedFile);
      Utils.postFile(
        "/api/promotion_advancement/upload", 
        formData,
        instance,
        account
      ).then((result) => {
        alert("Job promotions uploaded");
        closeBulkModal();
        fetchData();
      });
      return false;
    }    


    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    // const thisYear = (new Date()).getFullYear(); 
    // const yearsArray = [];
    // for(let i=2022;i<=(thisYear+1);i++){
    //   yearsArray.push(i);
    // }
    // yearsArray.reverse();

    return <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Add Job Advacement"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            {modalTitle}
          </h2>
          <div className="modalBody">
            {modalBody}
          </div>
          <div className="modalFooter">
            <button className="btn btn-primary" onClick={()=>{
              if(modalJobTitle && modalPromotionTitle){
                saveJobAdvancement();
              }else{
                alert("Please enter a job title and promotion title.")
              }
            }}>OK</button>
            <button className="btn btn-primary" onClick={closeModal}>Cancel</button>
          </div>
        </Modal>

        <Modal
          isOpen={modalBulkIsOpen}
          onAfterOpen={afterOpenBulkModal}
          onRequestClose={closeBulkModal}
          style={modalBulkStyles}
          contentLabel="Add Job Advancement"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Bulk Upload
          </h2>
          <div className="modalBody">
            Please provide a CSV data file.
            
            <form>
              <input
                type="file"
                onChange={(e) => {
                  // console.log("e", e);
                  setSelectedFile(e.target.files[0])
                }}
              />
            </form>

          </div>
          <div>&nbsp;</div>
          <div className="modalFooter">
            <button className="btn btn-primary" onClick={()=>{

              uploadJobAdvancement();

            }}>OK</button>
            <button className="btn btn-primary" onClick={closeBulkModal}>Cancel</button>
          </div>
        </Modal>   

        <MaterialReactTable 
          columns={columns} 
          data={tableData}   
          globalFilterFn="contains" 
          enableStickyHeader
          renderTopToolbarCustomActions={({ table }) => {
            return <div> 
              <span className="sectionTableHeader">Position Promotions |</span>
              &nbsp;&nbsp;&nbsp;  

              <button className="btn btn-primary" onClick={()=>{ window.location.href = "/salaryGroups" }}>&lt;-- Go Back</button>
              &nbsp;&nbsp;&nbsp;
              
              <button className="btn btn-primary"
                onClick={(event)=>{

                  openModal();
                  
                  return false;
                }}
              >
                Add Job Advancement
              </button>
              
              &nbsp;&nbsp;&nbsp;

              <button className="btn btn-primary"
                onClick={(event)=>{
                  // console.log("SHOW BULK UPLOADER", event);
                  openBulkModal();
                  return false;
                }}
              >
                Upload Job Advancement  
              </button>
            </div>;
          }}  
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }        
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: tableData
          }}
        />      
    </div>;
};

export default PromotionAdvancements;