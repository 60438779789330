import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import jQuery from 'jquery';
import accounting from 'accounting';

import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';

import * as Utils from '../lib/utils';
import CONFIG from '../config';

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "400px"
  },
};

const modalBulkStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

// const data = [];
//nested data is ok, see accessorKeys in ColumnDef below  
const SalaryGroupRanges = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });

    // const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear());

    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [selectedGroup, setSelectedGroup] = useState({});

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalAction, setModalAction] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalSelectedSalaryRange, setModalSelectedSalaryRange] = useState(null);

    const [modalRangeLaborCategory, setModalRangeLaborCategory] = useState((new Date()).getFullYear());
    const [modalRangePositionCategory, setModalRangePositionCategory] = useState("");  
    const [modalRangePositionTitle, setModalRangePositionTitle] = useState("");
    const [modalRangeMinimum, setModalRangeMinimum] = useState("");  
    const [modalRangeMidpoint, setModalRangeMidpoint] = useState("");  
    const [modalRangeMaximum, setModalRangeMaximum] = useState("");  
    const [modalRangeGroupId, setModalRangeGroupId] = useState("");  
    const [modalRangeYear, setModalRangeYear] = useState("");  

    const [modalBulkIsOpen, setBulkIsOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const urlParams = Utils.getParams();
    const groupId = urlParams.group_id;
    const selectedYear = urlParams.year;

    let subtitle;
  

    function openBulkModal() {
      setBulkIsOpen(true);
    }     
    function afterOpenBulkModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeBulkModal() {
      setBulkIsOpen(false);
    }


    function openModal(action, userInfo) {
      setModalAction(action);
      setModalText(action, userInfo);
      setIsOpen(true);
    }     
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeModal() {
      setIsOpen(false);
    }

    
    function setModalText(action, rangeInfo) {
      // console.log("ACTION", action, rangeInfo);

      let header = "";
      let body = <div>
        <div>
          <TextField
            required
            fullWidth 
            id="rangePositionCategory"
            label="Position Category"
            variant="standard"
            defaultValue={rangeInfo ? rangeInfo.position_category : ""}
            onChange={evt => setModalRangePositionCategory(evt.target.value)} 
          /><br /><br />

          <TextField
            required
            fullWidth 
            id="rangeLaborCategory"
            label="Labor Category"
            variant="standard"
            defaultValue={rangeInfo ? rangeInfo.labor_category : ""}
            onChange={evt => setModalRangeLaborCategory(evt.target.value)} 
          /><br /><br />

          <TextField
            required
            fullWidth 
            id="rangePositionTitle"
            label="Position Title"
            variant="standard"
            defaultValue={rangeInfo ? rangeInfo.position_title : ""}
            onChange={evt => setModalRangePositionTitle(evt.target.value)} 
          /><br /><br />

          <TextField
            required
            fullWidth 
            id="rangeMinimum"
            label="Range Minimum"
            variant="standard"
            defaultValue={rangeInfo ? rangeInfo.minimum : ""}
            onChange={evt => setModalRangeMinimum(evt.target.value)} 
          /><br /><br />

          <TextField
            required
            fullWidth 
            id="rangeMidpoint"
            label="Range Midpoint"
            variant="standard"
            defaultValue={rangeInfo ? rangeInfo.midpoint : ""}
            onChange={evt => setModalRangeMidpoint(evt.target.value)} 
          /><br /><br />

          <TextField
            required
            fullWidth 
            id="rangeMaximum"
            label="Range Maximum"
            variant="standard"
            defaultValue={rangeInfo ? rangeInfo.maximum : ""}
            onChange={evt => setModalRangeMaximum(evt.target.value)} 
          /><br /><br />

        </div>
      </div>;

      if(action==="add"){
        header = "Add";
      }else if(action==="edit"){
        header = "Edit salary range.";
      }else if(action==="delete"){
        header = "Delete salary range?";
      }

      setModalRangeLaborCategory(rangeInfo ? rangeInfo.labor_category : "");
      setModalRangePositionCategory(rangeInfo ? rangeInfo.position_category : "");
      setModalRangePositionTitle(rangeInfo ? rangeInfo.position_tite : "");
      setModalRangeMinimum(rangeInfo ? rangeInfo.minimum : "");
      setModalRangeMidpoint(rangeInfo ? rangeInfo.midpoint : "");
      setModalRangeMaximum(rangeInfo ? rangeInfo.maximum : "");
      setModalRangeGroupId(groupId);
      setModalRangeYear(selectedYear);

      setModalSelectedSalaryRange(rangeInfo);
      setModalTitle(header);
      setModalBody(body);
    
      return false;
    }

    
    const fetchData = async () => {
      if (!tableData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const jsonResponse = await Utils.getData(
          ('/api/salary_range?group_id='+ groupId + '&year='+selectedYear), 
          instance, 
          account
        );
        
        setTableData(jsonResponse);
        setRowCount(jsonResponse.length);

        const groupResponse = await Utils.getData(
          ('/api/salary_range_group?year='+selectedYear+'&group_id='+groupId), 
          instance, 
          account
        );
        setSelectedGroup(groupResponse.length ? groupResponse[0] : {});

      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });

    //should be memoized or stable
    const columns = useMemo(
      () => [
        // {
        //   accessorKey: 'view',
        //   header: 'View Group Ranges',
        //   Cell: ({ cell }) => {
        //     return <div><a href={'/salaryGroupRanges?group_id=' + cell.row.getValue("id")}>View</a></div>
        //   },
        //   enableEditing: false,
        //   columnDefType: 'display',
        // },
        {
          accessorKey: 'delete_range',
          header: 'Delete Range',
          Cell: ({ cell, row }) => {
            return <div><a href="#" onClick={(event)=>{
              event.preventDefault();
              // console.log("EVENT", event, cell, row );
              if(window.confirm("Are you sure you would like to delete this group range?")){
                deleteSalaryRange(cell.row.original.id);
              }
              return false;
            }}>Delete</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        },            
        {
          accessorKey: 'id',
          header: 'Range Id',
          enableEditing: false
        },
        {
          accessorFn: (row)=>`${row.position_category ? row.position_category : ""}`,
          accessorKey: 'position_category',
          header: 'Position Category',
          enableEditing: true
        },
        {
          accessorKey: 'position_title',
          header: 'Position Title',
          enableEditing: true
        },
        {
          accessorFn: (row)=>`${row.labor_category ? row.labor_category : ""}`,
          accessorKey: 'labor_category',
          header: 'Labor Category',
          enableEditing: true,
        },
        {
          accessorFn: (row)=>`${row.minimum ? Utils.moneyMask(row.minimum, 0) : ""}`,
          accessorKey: 'minimum',
          header: 'Minimum',
          enableEditing: true,
        },
        {
          accessorFn: (row)=>`${row.midpoint ? Utils.moneyMask(row.midpoint, 0) : ""}`,
          accessorKey: 'midpoint',
          header: 'Midpoint',
          enableEditing: true,
        },        
        {
          accessorFn: (row)=>`${row.maximum ? Utils.moneyMask(row.maximum, 0) : ""}`,
          accessorKey: 'maximum',
          header: 'Maximum',
          enableEditing: true,
        },
      ],
      [],
    );



    //saves the group to the DB and updates the client view
    const saveSalaryRange = (action, range) => {
      // console.log("action", action, "range", range, "tableData", tableData)

      //DO THE ACTION
      let method;
      let data;

      if(action === "add"){
        const rangeMinimum = accounting.unformat(modalRangeMinimum);
        const rangeMidpoint = accounting.unformat(modalRangeMidpoint);
        const rangeMaximum = accounting.unformat(modalRangeMaximum);

        if(!Utils.isNumeric(rangeMinimum) || !Utils.isNumeric(rangeMidpoint) || !Utils.isNumeric(rangeMaximum)){
          alert("Minimum, midpoint, and maximum must all be numeric values.");
          return "Validation Failed";
        }
        method="postData";
        data = {
          labor_category : modalRangeLaborCategory,
          position_category: modalRangePositionCategory,
          position_title: modalRangePositionTitle,
          minimum: rangeMinimum,
          midpoint: rangeMidpoint,
          maximum: rangeMaximum,
          group_id: modalRangeGroupId,
          period_start: selectedYear + "-01-01", 
          period_end: selectedYear + "-12-31"
        }; 
      }

      if(action === "edit"){
        const rangeMinimum = accounting.unformat(range.minimum);
        const rangeMidpoint = accounting.unformat(range.midpoint);
        const rangeMaximum = accounting.unformat(range.maximum);
        
        if(!Utils.isNumeric(rangeMinimum) || !Utils.isNumeric(rangeMidpoint) || !Utils.isNumeric(rangeMaximum)){
          alert("Minimum, midpoint, and maximum must all be numeric values.");
          return "Validation Failed";
        }        
        method="putData";
        data = {
          id: range.id,
          labor_category : range.labor_category,
          position_category: range.position_category,
          position_title: range.position_title,
          minimum: rangeMinimum,
          midpoint: rangeMidpoint,
          maximum: rangeMaximum,
          group_id: range.group_id,
          period_start: selectedYear + "-01-01", 
          period_end: selectedYear + "-12-31"
        };
      }

      let localSalaryRanges = Utils.copy(tableData);

      // console.log("DATA", data, instance, account)

      let url = "/api/salary_range";
      if(action==="edit" || action==="delete"){
        url = url + "/" + range.id;
      }
      
      // console.log("IS FETCHING")

      Utils[method](url, data, instance, account).then((responseData) => {

        // console.log("START", localSalaryRanges)
        // console.log("HAS FETCHED", responseData);
        // console.log("range", range)
        
        //find the group in the local list, update as needed
        let rowMatched=false;
        for(var i=0;i<localSalaryRanges.length;i++){
          // console.log("localSalaryRanges[i].id==range.id", localSalaryRanges[i].id, range.id)
          if(range && localSalaryRanges[i].id==range.id){
            if(action==="edit"){
              localSalaryRanges[i] = responseData;
            }
            rowMatched=true;
            // console.log("MATCHED ROW!", range, localSalaryRanges[i].id, range.id)
            break;
          }
        }

        // console.log("responseData", responseData);

        // console.log("rowMatched", rowMatched);

        //add a group to the local list
        if(!rowMatched){
          localSalaryRanges.push(responseData)
        }

        setTableData(localSalaryRanges);
        setRowCount(localSalaryRanges.length);

        closeModal();

      });

      return false;
    }


    const deleteSalaryRange = (salaryRangeId)=>{
      Utils.deleteData(
        "/api/salary_range/" + salaryRangeId, 
        instance, 
        account
      ).then((data) => {
        // console.log("data", data)
        alert(data.message);

        if(data.result === "SUCCESS"){
          fetchData();
        }
      });
    }


    const handleSaveRow = async ({ exitEditingMode, row, values, table }) => {
      // console.log(row, values, table);
      const saveResult = saveSalaryRange("edit", values);
      if(saveResult != "Validation Failed"){
        // let data = table.getState().tableData; //deprecated
        let data = tableData;
        data[row.index] = values;
        setTableData([...data]);
        exitEditingMode();  
      }
    };



    const uploadSalaryRanges = () => {
      // console.log("Do it!");
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      Utils.postFile(
        "/api/salary_range/upload", 
        formData,
        instance,
        account
      ).then((result) => {
        alert("Salary ranges Uploaded");
        closeBulkModal();
        fetchData();
      });
      return false;
    }    



    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    const thisYear = (new Date()).getFullYear(); 
    const yearsArray = [];
    for(let i=2022;i<=(thisYear+1);i++){
      yearsArray.push(i);
    }
    yearsArray.reverse();

    // console.log("selectedGroup", selectedGroup);

    return <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Add Salary Group Range"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h4 className="modalTitle" style={{ textAlign:"center" }} ref={(_subtitle) => (subtitle = _subtitle)}>
            {modalTitle}
          </h4>
          <div className="modalBody">
            {modalBody}
          </div>
          <div className="modalFooter" style={{float:"right"}}>
            <Button onClick={closeModal}>
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button 
              variant="contained" 
              onClick={()=>{
              // console.log("PERFORM ACTION")
              saveSalaryRange(modalAction, modalSelectedSalaryRange);
            }}>
              Save
            </Button>
          </div>
        </Modal> 

        <Modal
          isOpen={modalBulkIsOpen}
          onAfterOpen={afterOpenBulkModal}
          onRequestClose={closeBulkModal}
          style={modalBulkStyles}
          contentLabel="Add Salary Group Range"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Bulk Upload
          </h2>
          <div className="modalBody">
            Please select a CSV file.

            <form>
              <input
                type="file"
                onChange={(e) => {
                  // console.log("e", e);
                  setSelectedFile(e.target.files[0])
                }}
              />
            </form>

          </div>
          <div>&nbsp;</div>
          <div className="modalFooter">
            <button className="btn btn-primary" onClick={()=>{

              // console.log("SELECTED FILE", selectedFile);
              uploadSalaryRanges();

            }}>OK</button>
            <button className="btn btn-primary" onClick={closeBulkModal}>Cancel</button>
          </div>
        </Modal>         

        <MaterialReactTable 
          columns={columns} 
          data={tableData}
          globalFilterFn="contains"
          enableStickyHeader 
          renderTopToolbarCustomActions={({ table }) => {
            return <div>
              <span className="sectionTableHeader">Salary Group Ranges</span>
              &nbsp;&nbsp;&nbsp;  

              <button className="btn btn-primary" onClick={()=>{ window.location.href = "/salaryGroups" }}>&lt;-- Go Back</button>
              &nbsp;&nbsp;&nbsp;
              
              <button className="btn btn-primary"
                onClick={(event)=>{
                  openModal("add", account);
                  return false;
                }}
              >
                Add Salary Range
              </button>

              &nbsp;&nbsp;&nbsp;

              <button className="btn btn-primary"
                onClick={(event)=>{
                  // console.log("SHOW BULK UPLOADER", event);
                  openBulkModal();
                  return false;
                }}
              >
                Upload Salary Ranges
              </button>
              
              &nbsp;&nbsp;&nbsp;
              
              Viewing&nbsp;
                {selectedYear}&nbsp;
                {
                  selectedGroup && 
                  selectedGroup.group_department!== "EXECUTIVES" && 
                  selectedGroup.group_department !== "PIPELINES" ? 
                    selectedGroup.group_department : 
                    ""
                }&nbsp;
                {selectedGroup ? selectedGroup.group_name : ""}
            </div>;
          }}            
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }   
          enableEditing
          // editDisplayMode="table"
          onEditingRowSave={handleSaveRow}        
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: tableData,
            // columnVisibility: { group_description: false }
          }}
        />      
    </div>;
};

export default SalaryGroupRanges;