import React, { useState, useMemo, useEffect }  from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import jQuery from 'jquery';
import * as Utils from '../lib/utils';
import { protectedResources } from "../config/authConfig";
import CONFIG from "../config/index.js";

window.jQuery = jQuery;
require("jsgrid");
  
// const hostUrl = (window.location.host.indexOf("localhost") > -1 ? "http://" : "https://") + window.location.host.replace("3000", "8080");
// console.log("CONFIG", CONFIG)

const AdminReports = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
 
  const [tableData, setTableData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);    
  const [rowCount, setRowCount] = useState(0);

  const [pendingAdjustmentsData, setPendingAdjustmentsData] = useState({});
  const [reviewAdjustmentsData, setReviewAdjustmentsData] = useState({});
  const [approvedAdjustmentsData, setApprovedAdjustmentsData] = useState({});
  const [closedAdjustmentsData, setClosedAdjustmentsData] = useState({});
  
  const [msalAuthorization, setMsalAuthorization] = useState("");
  
  const [employeeProfileReportYear, setEmployeeProfileReportYear] = useState((new Date()).getFullYear());

  const fetchData = async () => {
    if (!tableData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {

      const authResp = await instance.acquireTokenSilent({
        scopes: protectedResources["apiCompensation"].scopes,
        account: account
      });
      setMsalAuthorization(authResp);
      
      const jsonResponse = await Utils.getData(
        ('/api/employee_adjustment/details'), 
        instance, 
        account
      );

      setTableData(jsonResponse);
      setRowCount(jsonResponse.length);
      

      const jsonPendingAdjustmentsResponse = await Utils.getData(
        ('/api/pending'), 
        instance, 
        account
      );
      setPendingAdjustmentsData(jsonPendingAdjustmentsResponse);

      const jsonReviewAdjustmentsResponse = await Utils.getData(
        ('/api/review'), 
        instance, 
        account
      );
      setReviewAdjustmentsData(jsonReviewAdjustmentsResponse);

      const jsonApprovedAdjustmentsResponse = await Utils.getData(
        ('/api/approved'), 
        instance, 
        account
      );
      setApprovedAdjustmentsData(jsonApprovedAdjustmentsResponse);
      
      const jsonClosedAdjustmentsResponse = await Utils.getData(
        ('/api/closed'), 
        instance, 
        account
      );
      setClosedAdjustmentsData(jsonClosedAdjustmentsResponse);
      
      
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  
  const triggerLink = (linkId, noToken)=>{
    
    instance.acquireTokenSilent({
      scopes: protectedResources["apiCompensation"].scopes,
      account: account
    }).then((msalAuthorization)=>{
      //add the token
      let selectedLink = document.getElementById(linkId).href
      if(!noToken){
        selectedLink = selectedLink + "&token=" + (msalAuthorization ? msalAuthorization.accessToken : "")
      } 
      
      //set the link handler
      document.getElementById("linkHandler").href = selectedLink;
      
      //click the link
      document.getElementById("linkHandler").click()
    });
  }  
  
    
  // console.log("instance", instance, "account", account, "msalAuthorization", msalAuthorization);  

  return <div>
    <div>
      {pendingAdjustmentsData && pendingAdjustmentsData.length ? (<div>
        Non-Submitted Compensation Adjustments:
        {pendingAdjustmentsData.map((pendingAdjustmentItem)=>{
          return <div className="hrAdjustmentRow wide wide" key={Utils.guid()}>
            <span className='hrViewPendingRequests wide'>
              <a 
                id={"pendingCompensations" + pendingAdjustmentItem.effective_date}
                href={
                  CONFIG.API_HOST + 
                  "/api/compensation/employees?manager_email=" + account.username +
                  "&status=pending" +
                  "&effective_date=" + pendingAdjustmentItem.effective_date +
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("pendingCompensations" + pendingAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download Pay Data
              </a>  
            </span>
            <span className='hrViewPendingRequests wide'>
              <a 
                id={"pendingBudgetData" + pendingAdjustmentItem.effective_date}
                href={
                  CONFIG.API_HOST + 
                  "/api/budget/report?manager_email=" + account.username+
                  "&status=pending" +
                  "&effective_date=" + pendingAdjustmentItem.effective_date +
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("pendingBudgetData" + pendingAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download Budget Spend
              </a>  
            </span>       
            <span className='hrLabelDateRequests'>
              Effective Date: {Utils.maskDateAs("mdy", new Date(pendingAdjustmentItem.effective_date + " 12:00:00"))} <br />
              Deadline Date: {pendingAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(pendingAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {pendingAdjustmentItem.num_employees}</span>
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null}
    </div>      
    
      <div>
      {reviewAdjustmentsData && reviewAdjustmentsData.length ? (<div>
        Submitted Compensation Adjustments: 
        {reviewAdjustmentsData.map((reviewAdjustmentItem)=>{
          return <div className="hrAdjustmentRow wide wide" key={Utils.guid()}>
            <span className='hrViewPendingRequests wide'>
              <a 
                id={"reviewCompensations" + reviewAdjustmentItem.effective_date}
                href={
                  CONFIG.API_HOST + 
                  "/api/compensation/employees?manager_email=" + account.username+
                  "&status=review" +
                  "&effective_date=" + reviewAdjustmentItem.effective_date +
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("reviewCompensations" + reviewAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download Pay Data
              </a>  
            </span>
            <span className='hrViewPendingRequests wide'>
              <a 
                id={"reviewBudgetData" + reviewAdjustmentItem.effective_date}
                href={
                  CONFIG.API_HOST + 
                  "/api/budget/report?manager_email=" + account.username+
                  "&status=review" +
                  "&effective_date=" + reviewAdjustmentItem.effective_date +
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("reviewBudgetData" + reviewAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download Budget Spend
              </a>  
            </span>   
            <span className='hrLabelDateRequests'>
              Effective Date: {Utils.maskDateAs("mdy", new Date(reviewAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {reviewAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(reviewAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {reviewAdjustmentItem.num_employees}</span>
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null}
    </div>
    
    <div>
      {approvedAdjustmentsData && approvedAdjustmentsData.length ? (<div>
        Approved Compensation Adjustments:
        {approvedAdjustmentsData.map((approvedAdjustmentItem)=>{
          return <div className="hrAdjustmentRow wide wide" key={Utils.guid()}>
            <span className='hrViewPendingRequests wide'>
              <a 
                id={"approvedCompensations" + approvedAdjustmentItem.effective_date}
                href={
                  CONFIG.API_HOST + 
                  "/api/compensation/employees?manager_email=" + account.username+
                  "&status=approved" +
                  "&effective_date=" + approvedAdjustmentItem.effective_date +
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("approvedCompensations" + approvedAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download Pay Data
              </a>  
            </span>
            <span className='hrViewPendingRequests wide'>
              <a 
                id={"approvedBudgetData" + approvedAdjustmentItem.effective_date}
                href={
                  CONFIG.API_HOST + 
                  "/api/budget/report?manager_email=" + account.username+
                  "&status=approved" +
                  "&effective_date=" + approvedAdjustmentItem.effective_date +
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("approvedBudgetData" + approvedAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download Budget Spend
              </a>  
            </span>       
            <span className='hrLabelDateRequests'>
              Effective Date: {Utils.maskDateAs("mdy", new Date(approvedAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {approvedAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(approvedAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {approvedAdjustmentItem.num_employees}</span>
            <span className='hrDownloadChanges'>
              <a 
                id={"changesAndPromotions" + approvedAdjustmentItem.effective_date}
                href={CONFIG.API_HOST + '/api/employee_adjustment/changes/download?status=approved&effective_date=' + approvedAdjustmentItem.effective_date}
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("changesAndPromotions" + approvedAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download position/salary changes
              </a>
            </span>
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null}
    </div>
 
    <br />
    
    
    <div>
      {closedAdjustmentsData && closedAdjustmentsData.length ? (<div>
        Closed Compensation Adjustments: (Under Construction)
        {closedAdjustmentsData.map((closedAdjustmentItem)=>{
          return <div className="hrAdjustmentRow wide" key={Utils.guid()}>
            <span className='hrViewPendingRequests'>
              <a 
                id={"viewCompensations" + closedAdjustmentItem.effective_date}
                href={ 
                  "/compensation?effectiveDate=" + closedAdjustmentItem.effective_date +
                  "&status=closed"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink(("viewCompensations" + closedAdjustmentItem.effective_date), true);
                  return false;
                }}
              >
                View
              </a>   
            </span>            
            <span className='hrViewPendingRequests wide'>
              <a 
                id={"closedCompensations" + closedAdjustmentItem.effective_date}
                href={
                  CONFIG.API_HOST + 
                  "/api/compensation/employees?manager_email=" + account.username+
                  "&status=closed" +
                  "&effective_date=" + closedAdjustmentItem.effective_date +
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("closedCompensations" + closedAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download Pay Data
              </a>  
            </span>
            <span className='hrViewPendingRequests wide'>
              <a 
                id={"closedBudgetData" + closedAdjustmentItem.effective_date}
                href={
                  CONFIG.API_HOST + 
                  "/api/budget/report?manager_email=" + account.username+
                  "&status=closed" +
                  "&effective_date=" + closedAdjustmentItem.effective_date +
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("closedBudgetData" + closedAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download Budget Spend
              </a>  
            </span>       
            <span className='hrLabelDateRequests'>
              Effective Date: {Utils.maskDateAs("mdy", new Date(closedAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {closedAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(closedAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {closedAdjustmentItem.num_employees}</span>
            <span className='hrDownloadChanges'>
              <a 
                id={"changesAndPromotions" + closedAdjustmentItem.effective_date}
                href={CONFIG.API_HOST + '/api/employee_adjustment/changes/download?status=closed&effective_date=' + closedAdjustmentItem.effective_date}
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("changesAndPromotions" + closedAdjustmentItem.effective_date);
                  return false;
                }}
              >
                Download position/salary changes
              </a>
            </span>
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null}
    </div>
 
    <br />

    <div>
      <a 
        id="promotionTitles"
        href={CONFIG.API_HOST + '/api/promotion_advancement/employee_promotion_titles?d=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("promotionTitles");
          return false;
        }}
      >Download employee promotion titles</a>
    </div>
    
    <br />
    
    <div>
      <a 
        id="employeeReviewers"
        href={CONFIG.API_HOST + '/api/employee/reviewers/all?d=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("employeeReviewers");
          return false;
        }}
      >Download employee reviewers</a>
    </div>
    
    <br />
    
    <div>
      <a 
        id="employeeReviewersAlternate"
        href={CONFIG.API_HOST + '/api/employee/reviewers/all?explode=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("employeeReviewersAlternate");
          return false;
        }}
      >Download employee reviewers (Alternate)</a>
    </div>
    
    <br />

    <div>
      {
        //employeeProfileReportYear
      }
      <a 
        id="employeeProfiles"
        href={CONFIG.API_HOST + '/api/employee/profiles/all?d=true&range_year='+employeeProfileReportYear}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("employeeProfiles");
          return false;
        }}
      >Download employee profile data</a>
    </div>
    
    <br />

    <div>
      <a 
        id="remoteEmployees"
        href={CONFIG.API_HOST + '/api/employee/remotes/all?d=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("remoteEmployees");
          return false;
        }}
      >Download remote employees data</a>
    </div>
        
    <br />

    <div>
      <a 
        id="employeeProfilesMissingRanges"
        href={CONFIG.API_HOST + '/api/employee/profiles/all?d=true&missing_ranges=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("employeeProfilesMissingRanges");
          return false;
        }}
      >Download employees missing salary ranges</a>
    </div>

    <br />
    
    <div>
      <a 
        id="hrEmailLogs"
        href={CONFIG.API_HOST + '/api/log/email_report?d=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("hrEmailLogs");
          return false;
        }}
      >Download HR email log</a>
    </div>

    <br />

    <div>
      <a 
        id="hrAllLogs"
        href={CONFIG.API_HOST + '/api/log/download_all?d=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("hrAllLogs");
          return false;
        }}
      >Download all logs</a>
    </div>
       
    <br />
    
    {/* <div>
      <a 
        id="salaryRangesWithLocationsAndMissingData"
        href={CONFIG.API_HOST + '/api/salary_range/download?include_locations=true&only_missing_data=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("salaryRangesWithLocationsAndMissingData");
          return false;
        }}
      >Download salary ranges with missing data (includes locations)</a>
    </div>

    <br /> */}
    
    <div>
      <a 
        id="salaryRangesWithLocations"
        href={CONFIG.API_HOST + '/api/salary_range/download?include_locations=true'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("salaryRangesWithLocations");
          return false;
        }}
      >Download salary ranges data (with locations)</a>
    </div>
     
    <br />
    
    <div>
      <a 
        id="salaryRangesNoLocations"
        href={CONFIG.API_HOST + '/api/salary_range/download?include_locations=false'}
        onClick={(event)=>{
          event.preventDefault()
          triggerLink("salaryRangesNoLocations");
          return false;
        }}
      >Download salary ranges data (without locations)</a>
    </div>
    
    <br />
    <br />
    
    <a href="#" id="linkHandler" style={{ visibility:"hidden" }}></a>
    
    <div>
      <div>
        Request new report types by contacting 
        &nbsp;<a href="mailto://softwareengineeringsupport@geoengineers.com">softwareengineeringsupport@geoengineers.com</a>
      </div>
    </div>
  </div>;
};


  // <div>
  //   <a href={CONFIG.API_HOST + '/api/compensation/sample_pdf?token=' + (msalAuthorization ? msalAuthorization.accessToken : "")}>Download sample PDF</a>
  // </div>
  // <br />
  // <div>
  //   <a href={CONFIG.API_HOST + '/api/compensation/sample_xlsx?token=' + (msalAuthorization ? msalAuthorization.accessToken : "")}>Download sample XLSX</a>
  // </div>

    

export default AdminReports;