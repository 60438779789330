const baseProdUrl = 'https://comp-tool.geoengineers.com';
const baseStagingUrl = 'https://comp-tool-staging.geoengineers.com';
const isProd = window.location.href.indexOf(baseProdUrl) > -1;
const isStaging = window.location.href.indexOf(baseStagingUrl) > -1;
const isDev = isProd || isStaging ? false : true;
// const isDev = false;

let config = {};
let API_HOST = 'http://localhost:8080';
if(isProd){
	API_HOST = baseProdUrl;
}
if(isStaging){
	API_HOST = baseStagingUrl;
}

const CONFIG = {
	debug : isDev,
	staging : isStaging,
	disableYellowBox : true,
	reduxLoggerEnabled : false,	
    API_HOST : API_HOST,
};

export default CONFIG;