import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Datetime from 'react-datetime';
import jQuery from 'jquery';
import { MaterialReactTable } from 'material-react-table';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html';

import * as Utils from '../lib/utils';
import CONFIG from '../config';

import "react-datetime/css/react-datetime.css";
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
};

const requestCompensationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:"400px",
    width: "600px"
  },
};

const dateModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:"400px",
    width: "600px"
  },
};

const mailerHistoryModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:"700px",
    width: "1000px"
  },
};


window.jQuery = jQuery;
require("jsgrid");

const HR = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [activeUser, setActiveUser] = useState([]);
  const [userData, setUserData] = useState([]);
  const [pendingAdjustmentsData, setPendingAdjustmentsData] = useState({});
  const [reviewAdjustmentsData, setReviewAdjustmentsData] = useState({});
  const [approvedAdjustmentsData, setApprovedAdjustmentsData] = useState({});
  const [closedAdjustmentsData, setClosedAdjustmentsData] = useState({});
  
  
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);    
  const [rowCount, setRowCount] = useState(0);

  const [modalUserIsOpen, setUserIsOpen] = useState(false);
  const [modalUserAction, setModalUserAction] = useState("");
  const [modalUserBody, setModalUserBody] = useState("");
  const [modalSelectedUser, setModalSelectedUser] = useState(null);
  const [modalUserTitle, setModalUserTitle] = useState("");

  const [modalEmail, setModalEmail] = useState("");
  const [modalFirstName, setModalFirstName] = useState("");
  const [modalLastName, setModalLastName] = useState("");
  const [modalAccessLevel, setModalAccessLevel] = useState("");
  const [modalReviewContact, setModalReviewContact] = useState(false);

  const [modalCompensationIsOpen, setCompensationIsOpen] = useState(false);
  const [compensationOpenDate, setCompensationOpenDate] = useState("");
  const [compensationEffectiveDate, setCompensationEffectiveDate] = useState("");
  const [compensationDeadlineDate, setCompensationDeadlineDate] = useState("");
  
  const [overrideCurrentAdjustments, setOverrideCurrentAdjustments] = useState(false);
  
  const [modalEmployeeListIsOpen, setEmployeeListIsOpen] = useState(false);
  const [adjustmentEmployeeList, setAdjustmentEmployeeList] = useState([]);

  const [modalRequestResultsIsOpen, setRequestResultsIsOpen] = useState(false);
  const [ineligibleEmployees, setIneligibleEmployees] = useState([]);

  const [modalPreviewIsOpen, setPreviewIsOpen] = useState(false);

  const [modalEmailIsOpen, setEmailIsOpen] = useState(false);
  const [activeAdjustment, setActiveAdjustment] = useState(null);
  const [adjustmentStatus, setAdjustmentStatus] = useState("pending")
  const [adjustmentManagers, setAdjustmentManagers] = useState(null);

  const [modalDateIsOpen, setDateIsOpen] = useState(false);
  const [modalDateValue, setModalDateValue] = useState(null);
  const [modalDateType, setModalDateType] = useState(null);
  const [modalDateAdjustment, setModalDateAdjustment] = useState(null);

  const [mailerHistoryIsOpen, setMailerHistoryIsOpen] = useState(false);  
  const [mailerHistoryData, setMailerHistoryData] = useState(false);  
  const [displayMailerMessage, setDisplayMailerMessage] = useState(false);  
  const [selectedMailerMessage, setSelectedMailerMessage] = useState(false);  
  const [selectedMailerSubject, setSelectedMailerSubject] = useState(false);  
  
  

  const [emailContents, setEmailContents] = useState("");
  const [emailSubject, setEmailSubject] = useState("Compensation system request from HR");
  const [isSaving, setIsSaving] = useState(false);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(emailContents)
      )
    )
  )
  const [currentEditorValue, setCurrentEditorValue] = useState(draftToHtml(convertToRaw(editorState.getCurrentContent())));

  const [selectedManagers, setSelectedManagers] = useState([])

  const thisYear = (new Date()).getFullYear();

  const employeeHrisId = props && props.userAccount && props.userAccount.employee_hris_id ? props.userAccount.employee_hris_id : -1;

  let subtitle;

  const onEditorStateChange = (editorStateEvent)=> {
    // handleEmailTemplate(editorStateEvent)
    setEditorState(editorStateEvent);
  };


  function openUserModal(action, userInfo) {
    setModalUserAction(action);
    setUserModalText(action, userInfo);
    setUserIsOpen(true);
  }
  function setUserModalText(action, userInfo) {

    let header = "";
    let body = action !== "delete" ? 
      (<div>
        <div>
          <span className="hrUserInputLabel">Email: </span>
          <input name="email" id="email" defaultValue={userInfo ? userInfo.email : ""} onChange={evt => setModalEmail(evt.target.value)} />
          <br />
          <span className="hrUserInputLabel">First Name: </span>
          <input name="firstName" id="firstName" defaultValue={userInfo ? userInfo.first_name : ""} onChange={evt => setModalFirstName(evt.target.value)} />
          <br />
          <span className="hrUserInputLabel">Last Name: </span>
          <input name="lastName" id="lastName" defaultValue={userInfo ? userInfo.last_name : ""} onChange={evt => setModalLastName(evt.target.value)} />
          <br />
          <span className="hrUserInputLabel">Access Level: </span>
          <input name="accessLevel" id="accessLevel" defaultValue={userInfo ? userInfo.access_level : ""} onChange={evt => setModalAccessLevel(evt.target.value)} />
          <br />
          <span className="hrUserInputLabel">Is Review Contact? </span>
          <input type="checkbox" name="isReviewContact" id="isReviewContact" defaultChecked={userInfo ? userInfo.review_contact : false} onChange={(evt) => {
            setModalReviewContact(evt.target.checked);
          }} />
        </div>
      </div>) : 
      ("Delete the following user: " + userInfo.email + "?");

    if(action==="add"){
      header = "Add a user.";
    }else if(action==="edit"){
      header = "Edit user.";
    }else if(action==="delete"){
      header = "Delete user?";
    }

    setModalEmail(userInfo ? userInfo.email : "");
    setModalFirstName(userInfo ? userInfo.first_name : "");
    setModalLastName(userInfo ? userInfo.last_name : "");
    setModalAccessLevel(userInfo ? userInfo.access_level : "");
    setModalReviewContact(userInfo ? userInfo.review_contact : false);

    setModalSelectedUser(userInfo);
    setModalUserTitle(header);
    setModalUserBody(body);
  }
  function afterOpenUserModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeUserModal() {
    setUserIsOpen(false);
  }

  

  function openEmployeeListModal() {
    setEmployeeListIsOpen(true);
  }
  function afterOpenEmployeeListModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeEmployeeListModal() {
    setEmployeeListIsOpen(false);
  }



  function openCompensationModal() {
    setCompensationIsOpen(true);
  }
  function afterOpenCompensationModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeCompensationModal() {
    setCompensationIsOpen(false);
  }


  function openRequestResultsModal() {
    setRequestResultsIsOpen(true);
  }
  function afterOpenRequestResultsModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeRequestResultsModal() {
    setRequestResultsIsOpen(false);
  }


  function openPreviewModal(adjustmentItem, managersList, status) {
    setPreviewIsOpen(true);
  }
  function afterOpenPreviewModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closePreviewModal() {
    setPreviewIsOpen(false);
  }

  function saveEmailTemplate(){
    const newEmailContents = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    Utils.putData(
      ("/api/email_template/" + adjustmentStatus), 
      {
        body : newEmailContents,
        subject : emailSubject
      },
      instance,
      account
    ).then((data) => {
      alert("Template settings saved.")
    }).catch(()=>{
      alert("There was an error saving the template.  If you continue to encounter errors, please reach out to \"softwareengineeringsupport@geoengineers.com\"");
    });
    
    return false;
  }


  function openEmailModal(adjustmentItem, managersList, status, selectedTemplateObject) {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday","Thursday", "Friday","Saturday"]
    const date = new Date(adjustmentItem.effective_date);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const dayOfWeek = date.getDay();
    const cleanDate = days[dayOfWeek] + ", " + month + " " + day + ", " + year;

    let thisEmail = selectedTemplateObject.body;

    //Saturday September 30th, 2023u
    thisEmail = thisEmail.replace("#EFFECTIVEDATE#", cleanDate);
    setEmailSubject(selectedTemplateObject.subject);
    setEmailContents(thisEmail);
    setCurrentEditorValue(selectedTemplateObject.body);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(thisEmail)
        )
      )
    )    
    setAdjustmentStatus(status);
    setActiveAdjustment(adjustmentItem);
    setAdjustmentManagers(managersList);
    setEmailIsOpen(true);
  }
  function afterOpenEmailModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeEmailModal() {
    //end change change check
    setEmailIsOpen(false);
  }


  function openDateModal(adjustmentItem, dateType, status) {
    let dateValue = null;
    if(dateType === "Deadline"){
      dateValue = adjustmentItem.deadline_date;
    }
    if(dateType === "Effective"){
      dateValue = adjustmentItem.effective_date;      
    }
    if(dateType === "Open"){
      dateValue = adjustmentItem.open_date;      
    }
    
    setModalDateType(dateType);
    setModalDateValue(dateValue);
    setModalDateAdjustment(adjustmentItem);
    setDateIsOpen(true);
  }
  function afterOpenDateModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeDateModal() {
    setDateIsOpen(false);
  }


  function openMailerHistoryModal(adjustmentItem, dateType, status) {
    Utils.getData(
      ('/api/email_history'), 
      instance, 
      account
    ).then((results)=>{
      setMailerHistoryData(results);
    });

    setMailerHistoryIsOpen(true);
  }
  function afterOpenMailerHistoryModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeMailerHistoryModal() {
    setDisplayMailerMessage(false);
    setMailerHistoryIsOpen(false);
  }


  //saves the user to the DB and updates the client view
  function saveSelectedUser(action, user){
    //DO THE ACTION
    let method;
    let data;

    if(action === "add"){
      method="postData";
      data = {
        email: modalEmail,
        first_name: modalFirstName,
        last_name: modalLastName,
        review_contact: modalReviewContact,
        access_level: modalAccessLevel
      };
    }

    if(action === "edit"){
      method="putData";
      data = {
        id: user.id,
        email: modalEmail,
        first_name: modalFirstName,
        last_name: modalLastName,
        review_contact: modalReviewContact,
        access_level: modalAccessLevel
      };
    }

    if(action === "delete"){
      method="deleteData";  
      data = { id: user.id };
    }

    let localUsers = Utils.copy(userData);

    let fetchAction = action === "delete" ? 
      Utils[method]("/api/user/"+user.id, instance, account) : 
      Utils[method]("/api/user", data, instance, account);
    //update the user on the server
    
    fetchAction.then((data) => {
        
        //find the user in the local list, update as needed
        let rowMatched=false;
        for(var i=0;i<localUsers.length;i++){
          if(user && localUsers[i].id===user.id){
            if(action==="edit"){
              localUsers[i] = data;
            }
            if(action==="delete"){
              localUsers.splice(i, 1);
            }
            rowMatched=true;
            break;
          }
        }

        //add a user to the local list
        if(!rowMatched){
          localUsers.push(data)
        }

        setUserData(localUsers);
        fetchData();
        closeUserModal();

    });

    return false;
  }


  const fetchData = async () => {
    if (!userData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      
      const jsonUsersResponse = await Utils.getData(
        ('/api/user?type=admins'), 
        instance, 
        account
      );

      setUserData(jsonUsersResponse);
      let selectedUser = null
      for(var i=0;i<jsonUsersResponse.length;i++){
        if(accounts[0].username === jsonUsersResponse[i].email){
          selectedUser = jsonUsersResponse[i];
        }
      }
      setActiveUser(selectedUser);

      setRowCount(jsonUsersResponse.length);

      const jsonPendingAdjustmentsResponse = await Utils.getData(
        ('/api/pending'), 
        instance, 
        account
      );
      setPendingAdjustmentsData(jsonPendingAdjustmentsResponse);

      const jsonReviewAdjustmentsResponse = await Utils.getData(
        ('/api/review'), 
        instance, 
        account
      );
      setReviewAdjustmentsData(jsonReviewAdjustmentsResponse);

      const jsonApprovedAdjustmentsResponse = await Utils.getData(
        ('/api/approved'), 
        instance, 
        account
      );
      setApprovedAdjustmentsData(jsonApprovedAdjustmentsResponse);

      const jsonClosedAdjustmentsResponse = await Utils.getData(
        ('/api/closed'), 
        instance, 
        account
      );
      setClosedAdjustmentsData(jsonClosedAdjustmentsResponse);


    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    
    fetchData();

  }, []);
  


  const requestCompensations = () =>{
  
    if(compensationEffectiveDate){
      Utils.postData(
        "/api/employee_adjustment/all", 
        {
          override_current_adjustments: overrideCurrentAdjustments,
          open_date: compensationOpenDate,
          effective_date: compensationEffectiveDate,
          deadline_date: compensationDeadlineDate 
        },
        instance, 
        account
      ).then((data) => {
  
        closeCompensationModal();
        fetchData();
        alert(data.message);

      }).catch((error)=>{
        
        console.log("ERROR", error);
        alert("There was an error creating the requested compensation.");
        
      });
    }else{
      alert("Opening compensation requires an effective date to be set.")
    }
  
    return false;
  }    


  const closeAdjustmentByDateAndStatus = (effectiveDate, status) =>{

    Utils.postData(
      "/api/employee_adjustment/close?effective_date=" + effectiveDate + "&status=" + status,
      {},
      instance, 
      account
    ).then((data) => {

      fetchData();

      if(data && data.result && data.result ==="FAILED"){
        alert(data.message);
      }else{
        alert("Adjustment successfully closed");
      }

    });
    
    return false;
  }


  const deleteAdjustmentByDateAndStatus = (effectiveDate, status) =>{
      Utils.deleteData(
        "/api/employee_adjustment?effective_date=" + effectiveDate + "&status=" + status, 
        instance, 
        account
      ).then((data) => {
  
        fetchData();

        if(data && data.result && data.result ==="FAILED"){
          alert(data.message);
        }else{
          alert("Adjustment successfully deleted");
        }

      });
  }

  
  const handleSelectManager = (e, target)=> {
    const options = [...e.target.selectedOptions];
    const values = options.map(option => option.value);
    setSelectedManagers(values);
    return false;
  };


  const emailManagers = () =>{
    const thisManagers = adjustmentManagers.filter((managerItem)=>{
      return selectedManagers.indexOf(managerItem.id.toString()) > -1;
    });
    // console.log("DEV MODE, ONLY USING JSTOPCHICK@GEOENGINEERS.COM FOR TESTING PURPOSES")

    if(thisManagers.length){
      Utils.postData(
        "/api/compensation/email", 
        {
          manager_list :  thisManagers,
          email_body : draftToHtml(convertToRaw(editorState.getCurrentContent())),
          email_subject: emailSubject,
          status: adjustmentStatus,
          effective_date: activeAdjustment.effective_date
        },
        instance, 
        account
      ).then((data) => {
        alert("Managers emailed.");
        closeEmailModal();
      });
  
    }else{
      alert("Please select at least one manager to continue.");
    }

  }
  

  const processIneligibleEmployees = (adjustmentsArray)=>{
    let employeeList = [];

    for(var i=0;i<adjustmentsArray.length;i++){
      //check if there are indeed ineligible employees
      if(
        adjustmentsArray[i]
        && adjustmentsArray[i].adjustment_details
        && adjustmentsArray[i].adjustment_details.ineligible_employees
      ){

      for(var j=0;j<adjustmentsArray[i].adjustment_details.ineligible_employees.length;j++){
        //if the ineligable employee isn't already in the list, add them
          if(!employeeList.find((employeeItem)=>{
            return employeeItem.first_name === adjustmentsArray[i].adjustment_details.ineligible_employees[j].first_name
              && employeeItem.first_name === adjustmentsArray[i].adjustment_details.ineligible_employees[j].last_name
          })){

            //only show employees with a matching status (can be seen in the employee_list_status in this object
            if(
              adjustmentsArray[i].employee_list && adjustmentsArray[i].employee_list.indexOf(
                adjustmentsArray[i].adjustment_details.ineligible_employees[j].employee_hris_id
              ) > -1
            ){
              employeeList.push(adjustmentsArray[i].adjustment_details.ineligible_employees[j]);
            }

          }

        }
      }
    }

    return employeeList;
  }

  
  const matchedAccount = userData.find((userObject)=>{
    return userObject.email === accounts[0].username;
  });
  const authorized = accounts && accounts.length && accounts[0].username && matchedAccount && matchedAccount.email === accounts[0].username;
  const sampleUrl = "https://comp-tool.geoengineers.com/compensation?managerHrisId=-1&status=" + (adjustmentStatus==="closed" ? "approved" : adjustmentStatus);

  const mailerHistoryColumns = [
    {
      accessorKey: 'view_details',
      header: 'View',
      enableEditing: false,
      Cell: ({ cell, row }) => {
        return <div><a href="#" onClick={(event)=>{
          event.preventDefault();
          setSelectedMailerMessage(row.original.body);
          setSelectedMailerSubject(row.original.subject);
          setDisplayMailerMessage(true);
          return false;
        }}>View Message</a></div>
      },
    },
    {
      accessorFn: (row) => `${(new Date(row.date_sent)).toLocaleString("en-US")}`,
      accessorKey: 'date_sent',
      header: 'Date Sent',
      enableEditing: false
    },     
    {
      accessorKey: 'recipient_email',
      header: 'Recipient',
      enableEditing: false
    },    
    {
      accessorKey: 'subject',
      header: 'Mail Subject',
      enableEditing: false
    },
  ];

  return authorized ? (<div>
    <Modal
      isOpen={modalUserIsOpen}
      onAfterOpen={afterOpenUserModal}
      onRequestClose={closeUserModal}
      style={modalStyles}
      contentLabel="User Details"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        {modalUserTitle}
      </h2>
      <div className="modalBody">
        {modalUserBody}
      </div>
      <div className="modalFooter">
        <button className="btn btn-primary" onClick={()=>{
          saveSelectedUser(modalUserAction, modalSelectedUser);
        }}>{modalUserAction === "delete" ? "Delete" : "Save"}</button>
        <button className="btn btn-primary" onClick={closeUserModal}>Cancel</button>
      </div>
    </Modal> 

    <Modal
      isOpen={modalEmployeeListIsOpen}
      onAfterOpen={afterOpenEmployeeListModal}
      onRequestClose={closeEmployeeListModal}
      style={modalStyles}
      contentLabel="Adjustment Employees"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Viewing Adjustment Employees
      </h2>
      <div className="modalBody" style={{ maxHeight: "300px", overflowY: "scroll" }}>
        {adjustmentEmployeeList.map((employeeItem)=>{
          return <div key={Utils.guid()}>
            <span>{Utils.decodeHTML(employeeItem.last_name)}, {Utils.decodeHTML(employeeItem.first_name)}</span>
          </div>
        })}
      </div>
      <div className="modalFooter">
        <button className="btn btn-primary" onClick={closeEmployeeListModal}>Close</button>
      </div>
    </Modal> 
       
    <Modal
      isOpen={modalCompensationIsOpen}
      onAfterOpen={afterOpenCompensationModal}
      onRequestClose={closeCompensationModal}
      style={requestCompensationModalStyles}
      contentLabel="Request Adjustments"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Open Compensation
      </h2>
      <div className="modalBody">
        Opening Date: 
        <Datetime 
            dateFormat="MM-DD-YYYY" 
            timeFormat={false}
            closeOnSelect={true} 
            onChange={e => {
              // console.log(e, e.format("MM-DD-YYYY"));
              setCompensationOpenDate(e.format("MM-DD-YYYY"))
            }} 
        /><br />
        Effective Date: 
        <Datetime 
            dateFormat="MM-DD-YYYY" 
            timeFormat={false}
            closeOnSelect={true} 
            onChange={e => {
              // console.log(e, e.format("MM-DD-YYYY"));
              setCompensationEffectiveDate(e.format("MM-DD-YYYY"))
            }} 
        /><br />
        Deadline Date: 
        <Datetime 
            dateFormat="MM-DD-YYYY" 
            timeFormat={false}
            closeOnSelect={true} 
            onChange={e => {
              setCompensationDeadlineDate(e.format("MM-DD-YYYY"))
            }} 
        />
        <br />
        Override current open adjustments: 
        <input 
          type='checkbox' 
          checked={overrideCurrentAdjustments}
          id='overrideCurrentAdjustments' 
          name='overrideCurrentAdjustments' 
          onChange={e => {
            setOverrideCurrentAdjustments(e.target.checked)
          }} /><br />
      </div>
      <div className="modalFooter">
        <button className="btn btn-primary" onClick={()=>{

          requestCompensations();

        }}>OK</button>
        <button className="btn btn-primary" onClick={closeCompensationModal}>Cancel</button>
      </div>
    </Modal> 

    <Modal
      isOpen={modalRequestResultsIsOpen}
      onAfterOpen={afterOpenRequestResultsModal}
      onRequestClose={closeRequestResultsModal}
      style={modalStyles}
      contentLabel="Request Results"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Employees Excluded from Compensation Request
      </h2>
      <div className="modalBody modalRequestResultBody">
        <div>
          <span className="hrLabelPendingRequests wide"><b>Employee Number</b></span>
          <span className="hrLabelPendingRequests wide"><b>First Name</b></span>
          <span className="hrLabelPendingRequests wide"><b>Last Name</b></span>
          <span className="hrLabelPendingRequests"><b>Date of Hire</b></span>
        </div>
        {
            ineligibleEmployees ? 
            ineligibleEmployees.sort((a,b)=>{
              var textA = a.first_name.toUpperCase();
              var textB = b.first_name.toUpperCase();              
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }
        ).map((employeeObject)=>{
          return <div key={Utils.guid()}>
            <span className="hrLabelPendingRequests wide">{employeeObject.employee_hris_id}</span>
            <span className="hrLabelPendingRequests wide">{Utils.decodeHTML(employeeObject.first_name)}</span>
            <span className="hrLabelPendingRequests wide">{Utils.decodeHTML(employeeObject.last_name)}</span>
            <span className="hrLabelPendingRequests">{Utils.maskDateAs("mdy", new Date(employeeObject.date_of_hire.substring(0,10) + " 12:00:00"))}</span>
          </div>;
        }) : "No exclusions for this adjustment"}
      </div>
      <div className="modalFooter">
        <button className="btn btn-primary" onClick={()=>{
          closeRequestResultsModal()
        }}>OK</button>
      </div>
    </Modal>

    <Modal
      isOpen={modalEmailIsOpen}
      onAfterOpen={afterOpenEmailModal}
      onRequestClose={closeEmailModal}
      style={modalStyles}
      contentLabel="Send Emails"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Send compensation email to managers
      </h2>
      <div className="modalBody">

        <span style={{ fontWeight:"bold",fontSize:"10pt", fontStyle:"italic" }}>
          Note: Use Ctrl+Click to select and deselect.
        </span><br />
        <select 
          multiple={true} 
          name='managers_list' 
          value={selectedManagers} 
          onChange={(event)=>{
            event.preventDefault();
            handleSelectManager(event, event.target);
            return false;
          }}
          style={{
            width: "300px"
          }}
        >
          {/* <option key={Utils.guid()} value="-1">--All Managers--</option> */}
          {adjustmentManagers ? adjustmentManagers.map((adjustmentManagerItem)=>{
            return <option key={Utils.guid()} value={adjustmentManagerItem.id}>
              {Utils.decodeHTML(adjustmentManagerItem.last_name)}, {Utils.decodeHTML(adjustmentManagerItem.first_name)}
            </option>;
          }) : null}
        </select><br /><br />

        Subject: 
        <TextField 
          type="text" 
          name="emailSubject" 
          id="emailSubject" 
          className="emailSubjectInput" 
          value={emailSubject} 
          fullWidth={true}
          onChange={(event)=>{setEmailSubject(event.target.value);}}
        />

        <Editor
          id="emailContents"
          editorState={editorState}
          wrapperClassName="hr-editor-wrapper"
          editorClassName="hr-editor-input"
          onEditorStateChange={onEditorStateChange}
        />
        
      </div>
      <div className="modalFooter">
        <button className="btn btn-primary" onClick={()=>{
          saveEmailTemplate();
          return false;
        }}>Save Template</button>

        <button className="btn btn-primary" onClick={()=>{
          openPreviewModal();
          return false;
        }}>Preview Email</button>

        &nbsp;&nbsp;&nbsp;
      
        <button className="btn btn-primary" onClick={()=>{
          let confirmationMessage = "This will email the selected managers with a request for compensation updates.  Continue?";
          if(adjustmentStatus==="approved"){
            confirmationMessage = "This will email the selected managers confirming their adjustments are approved.  Continue?"
          }

          if(window.confirm(confirmationMessage)){
            emailManagers();
          }

          return false;
        }}>Send Emails</button>
        
        <button className="btn btn-primary" onClick={closeEmailModal}>Cancel</button>
      
      </div>
    </Modal> 


    <Modal
      isOpen={modalDateIsOpen}
      onAfterOpen={afterOpenDateModal}
      onRequestClose={closeDateModal}
      style={dateModalStyles}
      contentLabel="Change Date"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Set {modalDateType} Date
      </h2>
      <div className="modalBody">
        {modalDateType} Date: {modalDateValue}
        <Datetime 
            value={new Date(modalDateValue + " 12:00:00")}
            dateFormat="MM-DD-YYYY" 
            timeFormat={false}
            closeOnSelect={true} 
            onChange={e => {
              console.log(e);
              let dateValue;
              console.log("typeof dateValue", typeof e);
              if(typeof e !== "string"){
                dateValue = e.format("MM-DD-YYYY");
              }else{
                dateValue = e;
              }
              setModalDateValue(dateValue);
              return false;
            }} 
        />
      </div>
      <div className="modalFooter">
        <button className="btn btn-primary" onClick={()=>{
          const dateValueToCheck = new Date(modalDateValue);

          if(!isNaN(dateValueToCheck.getTime()) && dateValueToCheck instanceof Date && isFinite(dateValueToCheck)){
            Utils.postData(
              "/api/employee_adjustment/set_dates", 
              {
                open_date: modalDateAdjustment.open_date,
                effective_date: modalDateAdjustment.effective_date,
                deadline_date: modalDateAdjustment.deadline_date,
                new_date_type : modalDateType,
                new_date_value : modalDateValue
              },
              instance, 
              account
            ).then((data) => {
        
              closeDateModal();
              fetchData();
  
            }).catch((error)=>{
              
              console.log("ERROR", error);
              alert("There was an error creating the requested compensation.");
              
            });
          }else{
            window.alert("The date entered in invalid, please enter a valid date to continue.");
          }
          return false;
        }}>OK</button>
        <button className="btn btn-primary" onClick={closeDateModal}>Cancel</button>
      </div>
    </Modal> 


    
    <Modal
      isOpen={mailerHistoryIsOpen}
      onAfterOpen={afterOpenMailerHistoryModal}
      onRequestClose={closeMailerHistoryModal}
      style={mailerHistoryModalStyles}
      contentLabel="Mailer History"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Email History
      </h2>
      <div className="modalBody">
        
        {
        displayMailerMessage ? 
          <div>
            <div>Subject: {}</div>
            <div dangerouslySetInnerHTML={{ __html : selectedMailerMessage }} /> 
            <a href="#" onClick={()=>{
              setDisplayMailerMessage(false);
              return false;
            }}>Return to messages</a>
          </div> : 
          <MaterialReactTable 
            columns={mailerHistoryColumns} 
            data={mailerHistoryData}
            globalFilterFn="contains" 
            positionToolbarAlertBanner="bottom"
            muiTableContainerProps={{ sx: { height: "520px" } }}
            enableTopToolbar={false}
            enableSelectAll={false}
            state={{
              isLoading: false,
              showAlertBanner: false,
              showProgressBars: false,
              tableData: mailerHistoryData
            }}
          />
        }

      </div>
      <div className="modalFooter">
        <button className="btn btn-primary" onClick={closeMailerHistoryModal}>Close</button>
      </div>
    </Modal> 


    <Modal
      isOpen={modalPreviewIsOpen}
      onAfterOpen={afterOpenPreviewModal}
      onRequestClose={closePreviewModal}
      style={modalStyles}
      contentLabel="Preview email"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Email Preview
      </h2>
      <div className="modalBody previewEmailBlock">
      
        <Editor 
          toolbarHidden
          editorState={
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  draftToHtml(
                    convertToRaw(
                      editorState.getCurrentContent()
                    )
                  ).replace(/#COMPENSATIONLINK#/g, 
                    sampleUrl+
                    (adjustmentStatus==="approved"?("&effectiveDate="+(activeAdjustment?activeAdjustment.effective_date:"")):"")
                  )
                )
              )
            )
          } 
          readOnly={true} 
        /> 
      </div>
      <div className="modalFooter">
        <button className="btn btn-primary" onClick={closePreviewModal}>Close</button>
      </div>
    </Modal> 


    <div>
      <div className={'bold header'}>Access Controls</div>
      <div className={'bold'}>You are logged in as {accounts ? accounts[0].username : "UNKNOWN"}</div>
      <div>&nbsp;</div>
      <div>
        <div key={Utils.guid()} className={'bold'}>Users List</div>
        <div key={Utils.guid()}>&nbsp;</div>
        {userData.map((account)=>{
          return <div key={Utils.guid()}>
            <span onClick={()=>{
              openUserModal("edit", account)
            }} className="iconContainer"><EditIcon /></span>
            <span onClick={()=>{
              openUserModal("delete", account)
            }} className="iconContainer"><DeleteIcon /></span>
            <span className="emailContainer">{account.email} ({account.access_level})</span>
          </div>;
        })}
        <div key={Utils.guid()}>&nbsp;</div>
        <div>
          <button className="btn btn-primary" onClick={()=>{
              openUserModal("add", null)
          }}>Add New Administrator</button>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
    <div className={'bold header'}>Email History</div>
    <div>
      <a className={"emailHistoryLink"} style={{cursor:"pointer"}} onClick={(e)=>{
        e.preventDefault();
        openMailerHistoryModal();
        return false;
      }}>Show email history</a>      
      <br />
      <br />
    </div>
    <div className={'bold header'}>Adjustments</div>
    <div>
    <a href={'/compensation?managerHrisId='+employeeHrisId}>Show my open adjustments</a><br /><br />
    <a href='/compensation'>Show *all* open adjustments</a>
      <br />
      <br />
      {pendingAdjustmentsData && pendingAdjustmentsData.length ? (<div className="hrAdjustmentsTable">
        <strong>Non-Submitted Compensation Adjustments:</strong>
        {pendingAdjustmentsData.map((pendingAdjustmentItem)=>{
          return <div className="hrAdjustmentRow" key={Utils.guid()}>
            <span className='hrViewPendingRequests'><a href={'/adjustments?status=pending&effective_date=' + pendingAdjustmentItem.effective_date}>View</a></span>
            <span className='hrViewPendingRequests wide'><a href="#" onClick={(event)=>{
              event.preventDefault();

              Utils.getData(
                ('/api/compensation/managers?status=pending&effective_date='+pendingAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((adjustmentManagesResult)=>{

                Utils.getData(
                  ('/api/email_template?status=pending'), 
                  instance, 
                  account
                ).then((emailTemplatesResult)=>{

                  let filteredManagers = [];
                  let invalidManagers = [];
                  for(var i=0;i<adjustmentManagesResult.length;i++){
                    if(adjustmentManagesResult[i].email){
                      filteredManagers.push(adjustmentManagesResult[i]);
                    }else{
                      invalidManagers.push(adjustmentManagesResult[i].first_name + " " + adjustmentManagesResult[i].last_name);
                    }
                  }

                  let managerMessage = "The following managers are no longer valid.  Please review they are still members of the GeoEngineers Inc. team: " + invalidManagers.join("\n") + "\nPress OK to continue without these users.";

                  if(invalidManagers.length && window.confirm(managerMessage)){
                    openEmailModal(pendingAdjustmentItem, filteredManagers, "pending", emailTemplatesResult[0]);
                  }else{
                    openEmailModal(pendingAdjustmentItem, filteredManagers, "pending", emailTemplatesResult[0]);
                  }

                });

              });
              
              return false;
            }}>Email Managers</a></span>            
            <span className='hrLabelDateRequests'>
              Opening Date: <a href="#" onClick={()=>{
                openDateModal(pendingAdjustmentItem, "Open", "pending");
                return false;
              }}>{pendingAdjustmentItem.open_date ? Utils.maskDateAs("mdy", new Date(pendingAdjustmentItem.open_date + " 12:00:00")) : "No date set"}</a><br />
              Effective Date:  <a href="#" onClick={()=>{
                openDateModal(pendingAdjustmentItem, "Effective", "pending");
                return false;
              }}>{Utils.maskDateAs("mdy", new Date(pendingAdjustmentItem.effective_date + " 12:00:00"))}</a><br />
              Deadline Date:  <a href="#" onClick={()=>{
                openDateModal(pendingAdjustmentItem, "Deadline", "pending");
                return false;
              }}>{pendingAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(pendingAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"}</a>
            </span>
            <span className='hrLabelUuidRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment?status=pending&effective_date=' + pendingAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonEmployeeList)=>{
                  setAdjustmentEmployeeList(jsonEmployeeList);
                  openEmployeeListModal();
                });

                return false;
              }}>Employees</a>&nbsp;
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {pendingAdjustmentItem.num_employees}</span>
            <span className='hrViewPendingRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment/details?status=pending&effective_date=' + pendingAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonAdjustmentLogResponse)=>{
                  //get logged exclusions
                  const processEmployees = processIneligibleEmployees(jsonAdjustmentLogResponse);
                  setIneligibleEmployees(processEmployees);
                  openRequestResultsModal();
                });

                return false;
              }}>Exclusions</a>&nbsp;
            </span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is archive/close all compensation adjustments connected to this request.")){
                closeAdjustmentByDateAndStatus(pendingAdjustmentItem.effective_date, "pending");
              }
              return false;
            }}>Close</a></span>             
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is remove all compensation adjustments connected to this request.")){
                deleteAdjustmentByDateAndStatus(pendingAdjustmentItem.effective_date, "pending");
              }
              return false;
            }}>Delete</a></span>             
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null}
    </div>

    <div>
      {reviewAdjustmentsData && reviewAdjustmentsData.length ? (<div className="hrAdjustmentsTable">
        <strong>Submitted/Non-Approved Compensation Adjustments:</strong>
        {reviewAdjustmentsData.map((reviewAdjustmentItem)=>{
          return <div className="hrAdjustmentRow" key={Utils.guid()}>
            <span className='hrViewPendingRequests'><a href={'/adjustments?status=review&effective_date=' + reviewAdjustmentItem.effective_date}>View</a></span>
            <span className='hrViewPendingRequests wide'><a href="#" onClick={(event)=>{
              event.preventDefault();

              Utils.getData(
                ('/api/compensation/managers?status=review&effective_date='+reviewAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((adjustmentManagers)=>{
                
                Utils.getData(
                  ('/api/email_template?status=review'), 
                  instance, 
                  account
                ).then((emailTemplatesResult)=>{

                  let filteredManagers = [];
                  let invalidManagers = [];
                  for(var i=0;i<adjustmentManagers.length;i++){
                    if(adjustmentManagers[i].email){
                      filteredManagers.push(adjustmentManagers[i]);
                    }else{
                      invalidManagers.push(adjustmentManagers[i].first_name + " " + adjustmentManagers[i].last_name);
                    }
                  }

                  let managerMessage = "The following managers are no longer valid.  Please review they are still members of the GeoEngineers Inc. team: " + invalidManagers.join("\n") + "\nPress OK to continue without these users.";

                  if(invalidManagers.length && window.confirm(managerMessage)){
                    openEmailModal(reviewAdjustmentItem, adjustmentManagers, "review", emailTemplatesResult[0]);
                  }else{
                    openEmailModal(reviewAdjustmentItem, adjustmentManagers, "review", emailTemplatesResult[0]);
                  }

                });

              });
              
              return false;
            }}>Email Managers</a></span>            
            <span className='hrLabelDateRequests'>
              Opening Date: {reviewAdjustmentItem.open_date ? Utils.maskDateAs("mdy", new Date(reviewAdjustmentItem.open_date + " 12:00:00")) : "No date set"}<br />
              Effective Date: {Utils.maskDateAs("mdy", new Date(reviewAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {reviewAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(reviewAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelUuidRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment?status=review&effective_date=' + reviewAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonEmployeeList)=>{
                  setAdjustmentEmployeeList(jsonEmployeeList);
                  openEmployeeListModal();
                });

                return false;
              }}>Employees</a>&nbsp;
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {reviewAdjustmentItem.num_employees}</span>
            <span className='hrViewPendingRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment/details?status=review&effective_date=' + reviewAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonAdjustmentLogResponse)=>{
                  //get logged exclusions
                  const processEmployees = processIneligibleEmployees(jsonAdjustmentLogResponse);
                  setIneligibleEmployees(processEmployees);
                  openRequestResultsModal();
                });

                return false;
              }}>Exclusions</a>&nbsp;
            </span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is archive/close all compensation adjustments connected to this request.")){
                closeAdjustmentByDateAndStatus(reviewAdjustmentItem.effective_date, "review");
              }
              return false;
            }}>Close</a></span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is remove all compensation adjustments connected to this request.")){
                deleteAdjustmentByDateAndStatus(reviewAdjustmentItem.effective_date, "review");
              }
              return false;
            }}>Delete</a></span>                
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null}
    </div>

    <div>
      {approvedAdjustmentsData && approvedAdjustmentsData.length ? (<div className="hrAdjustmentsTable">
        <strong>Approved Compensation Adjustments: </strong>
        {approvedAdjustmentsData.map((approvedAdjustmentItem)=>{
          return <div className="hrAdjustmentRow" key={Utils.guid()}>
            <span className='hrViewPendingRequests'><a href={'/adjustments?status=approved&effective_date=' + approvedAdjustmentItem.effective_date}>View</a></span>
            <span className='hrViewPendingRequests wide'><a href="#" onClick={(event)=>{
              event.preventDefault();

              Utils.getData(
                ('/api/compensation/managers?status=approved&effective_date='+approvedAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((adjustmentManagers)=>{

                Utils.getData(
                  ('/api/email_template?status=approved'), 
                  instance, 
                  account
                ).then((emailTemplatesResult)=>{

                  let filteredManagers = [];
                  let invalidManagers = [];
                  for(var i=0;i<adjustmentManagers.length;i++){
                    if(adjustmentManagers[i].email){
                      filteredManagers.push(adjustmentManagers[i]);
                    }else{
                      invalidManagers.push(adjustmentManagers[i].first_name + " " + adjustmentManagers[i].last_name);
                    }
                  }

                  let managerMessage = "The following managers are no longer valid.  Please review they are still members of the GeoEngineers Inc. team: " + invalidManagers.join("\n") + "\nPress OK to continue without these users.";

                  if(invalidManagers.length && window.confirm(managerMessage)){
                    openEmailModal(approvedAdjustmentItem, adjustmentManagers, "approved", emailTemplatesResult[0]);
                  }else{
                    openEmailModal(approvedAdjustmentItem, adjustmentManagers, "approved", emailTemplatesResult[0]);
                  }
                });

              });
              
              return false;
            }}>Email Managers</a></span>            
            <span className='hrLabelDateRequests'>
              Opening Date: {approvedAdjustmentItem.open_date ? Utils.maskDateAs("mdy", new Date(approvedAdjustmentItem.open_date + " 12:00:00")) : "No date set"}<br />
              Effective Date: {Utils.maskDateAs("mdy", new Date(approvedAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {approvedAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(approvedAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelPendingRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment?status=approved&effective_date=' + approvedAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonEmployeeList)=>{
                  setAdjustmentEmployeeList(jsonEmployeeList);
                  openEmployeeListModal();
                });

                return false;
              }}>Employees</a>&nbsp;              
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {approvedAdjustmentItem.num_employees}</span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();

              Utils.getData(
                ('/api/employee_adjustment/details?status=approved&effective_date=' + approvedAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((jsonAdjustmentLogResponse)=>{
                //get logged exclusions
                const processEmployees = processIneligibleEmployees(jsonAdjustmentLogResponse);
                setIneligibleEmployees(processEmployees);
                openRequestResultsModal();
              });

              return false;
            }}>Exclusions</a>&nbsp;</span>   
            
            {activeUser.access_level === "SUPERADMIN" ? <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is archive/close all compensation adjustments connected to this request.")){
                closeAdjustmentByDateAndStatus(approvedAdjustmentItem.effective_date, "approved");
              }
              return false;
            }}>Close</a></span> : null}
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null} 
    </div>

    <div>
      {closedAdjustmentsData && closedAdjustmentsData.length ? (<div className="hrAdjustmentsTable">
        <strong>Closed/Finalized Compensation Adjustments:</strong>
        {closedAdjustmentsData.map((closedAdjustmentItem)=>{
          return <div className="hrAdjustmentRow" key={Utils.guid()}>
            <span className='hrViewPendingRequests'><a href={'/adjustments?status=closed&effective_date=' + closedAdjustmentItem.effective_date}>View</a></span>
            <span className='hrViewPendingRequests wide'><a href="#" onClick={(event)=>{
              event.preventDefault();

              Utils.getData(
                ('/api/compensation/managers?status=closed&effective_date='+closedAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((adjustmentManagers)=>{

                Utils.getData(
                  ('/api/email_template?status=closed'), 
                  instance, 
                  account
                ).then((emailTemplatesResult)=>{

                  let filteredManagers = [];
                  let invalidManagers = [];
                  for(var i=0;i<adjustmentManagers.length;i++){
                    if(adjustmentManagers[i].email){
                      filteredManagers.push(adjustmentManagers[i]);
                    }else{
                      invalidManagers.push(adjustmentManagers[i].first_name + " " + adjustmentManagers[i].last_name);
                    }
                  }

                  let managerMessage = "The following managers are no longer valid.  Please review they are still members of the GeoEngineers Inc. team: " + invalidManagers.join("\n") + "\nPress OK to continue without these users.";

                  if(invalidManagers.length && window.confirm(managerMessage)){
                    openEmailModal(closedAdjustmentItem, adjustmentManagers, "closed", emailTemplatesResult[0]);
                  }else{
                    openEmailModal(closedAdjustmentItem, adjustmentManagers, "closed", emailTemplatesResult[0]);
                  }
                });

              });
              
              return false;
            }}>Email Managers</a></span>            
            <span className='hrLabelDateRequests'>
              Opening Date: {closedAdjustmentItem.open_date ? Utils.maskDateAs("mdy", new Date(closedAdjustmentItem.open_date + " 12:00:00")) : "No date set"}<br />
              Effective Date: {Utils.maskDateAs("mdy", new Date(closedAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {closedAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(closedAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelPendingRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment?status=closed&effective_date=' + closedAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonEmployeeList)=>{
                  setAdjustmentEmployeeList(jsonEmployeeList);
                  openEmployeeListModal();
                });

                return false;
              }}>Employees</a>&nbsp;              
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {closedAdjustmentItem.num_employees}</span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();

              Utils.getData(
                ('/api/employee_adjustment/details?status=closed&effective_date=' + closedAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((jsonAdjustmentLogResponse)=>{
                //get logged exclusions
                const processEmployees = processIneligibleEmployees(jsonAdjustmentLogResponse);
                setIneligibleEmployees(processEmployees);
                openRequestResultsModal();
              });

              return false;
            }}>Exclusions</a>&nbsp;</span>
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null} 
    </div>

    <div>
      
      <button className="btn btn-primary" onClick={()=>{
        const year = (new Date()).getFullYear();

          openCompensationModal();

      }}>Create Compensation Requests For All Employees</button>
    </div>
    <div>&nbsp;</div>
    {/* <div>
      <button className="btn btn-primary" onClick={()=>{
        if(window.confirm("Warning: This will reset all manager approvals!")){
          Utils.deleteData(
            "/api/employee/mananger_approvals", 
            instance, 
            account
          ).then((data) => {
            alert("Approvals have been reset.");
          });  
        }
      }}>Reset all manager approvals</button>
    </div> */}
  </div>) : (<div>Unavailable</div>);
};

export default HR;