import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import Datetime from 'react-datetime';
import moment from 'moment';
import jQuery from 'jquery';
import * as Utils from '../lib/utils';

import "react-datetime/css/react-datetime.css";
// import "../index.css";

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:"500px",
    width: "600px"
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


const EmployeeList = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });
    
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const [modalCompensationIsOpen, setCompensationIsOpen] = React.useState(false);
    const [compensationOpenDate, setCompensationOpenDate] = useState("");
    const [compensationEffectiveDate, setCompensationEffectiveDate] = useState("");
    const [compensationDeadlineDate, setCompensationDeadlineDate] = useState("");
    const [overrideCurrentAdjustments, setOverrideCurrentAdjustments] = useState(false);

    const [selectedAddEmployee, setSelectedAddEmployee] = useState(0);

    const [selectedRevieweeEmployee, setSelectedRevieweeEmployee] = useState({});
    const [modalRevieweesIsOpen, setModalRevieweesIsOpen] = useState(false);
    const [selectedReviewees, setSelectedReviewees] = useState(false);

    const [selectedReviewerEmployee, setSelectedReviewerEmployee] = useState({});
    const [modalReviewersIsOpen, setModalReviewersIsOpen] = useState(false);
    const [selectedReviewers, setSelectedReviewers] = useState(false);

    const [modalGlobalReviewerIsOpen, setModalGlobalReviewerIsOpen] = useState(false);
                
    let subtitle;

    function openCompensationModal() {
      setCompensationIsOpen(true);
    }
    function afterOpenCompensationModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeCompensationModal() {
      setCompensationIsOpen(false);
    }
  

    function openGlobalReviewerModal(employeeObject, reviewersObject) {
      // console.log("employeeObject", employeeObject);

      setSelectedReviewerEmployee(employeeObject);
      setSelectedReviewers(reviewersObject)
      setModalGlobalReviewerIsOpen(true);
    }     
    function afterOpenGlobalReviewerModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeGlobalReviewerModal() {
      setModalGlobalReviewerIsOpen(false);
    }


    function openRevieweesModal(selectedReviewer, revieweesResult) {
      // console.log("selectedReviewer", selectedReviewer);
      // console.log("revieweesResult", revieweesResult)

      setSelectedReviewees(revieweesResult)
      setModalRevieweesIsOpen(true);
    }     
    function afterOpenRevieweesModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeRevieweesModal() {
      setModalRevieweesIsOpen(false);
    }
    
    
    
    function openReviewersModal(selectedReviewee, reviewersResult) {
      // console.log("selectedReviewee", selectedReviewee);
      // console.log("reviewersResult", reviewersResult)

      setSelectedRevieweeEmployee(selectedReviewee)
      setSelectedReviewers(reviewersResult)
      setModalReviewersIsOpen(true);
    }     
    function afterOpenReviewersModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeReviewersModal() {
      setModalReviewersIsOpen(false);
    }

    useEffect(() => {
      const fetchData = async () => {
        if (!tableData.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }
  
        try {

          const jsonResponse = await Utils.getData(
            ('/api/employee'), 
            instance, 
            account
          );

          setTableData(jsonResponse);
          setRowCount(jsonResponse.length);
        } catch (error) {
          setIsError(true);
          console.error(error);
          return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
      };
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });



    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: 'view_employee',
          header: 'View Employee',
          Cell: ({ cell, row }) => {
            // console.log("cell", cell, "row", row);
            return <div><a href={'/employee?id=' + row.original.id}>View</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        },
        {
          accessorKey: 'view_pending',
          header: 'Request Adjustment',
          Cell: ({ cell }) => {
            // return <div><a href={'/salaryGroupRanges?group_id=' + cell.row.getValue("id")}>View</a></div>
            return <div><a href="#" onClick={(ev)=>{
              ev.preventDefault();

              setSelectedEmployee([ cell.row.original ]);
              openCompensationModal();

              return false;
            }}>Request compensation</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
        },        
        {
          accessorKey: 'employee_hris_id',
          header: 'Employee Number',
          enableEditing: false
        },     
        {
          accessorFn: (row) => `${Utils.decodeHTML(row.first_name)}`,
          accessorKey: 'first_name',
          header: 'First Name',
          enableEditing: false
        },
        {
          accessorFn: (row) => `${Utils.decodeHTML(row.last_name)}`,
          accessorKey: 'last_name',
          header: 'Last Name',
          enableEditing: false
        },
        
        {
          accessorFn: (row) => {
            return <div>
              {row.adjustment_id_list && row.adjustment_id_list.length ? row.adjustment_id_list.map((adjustmentId)=>{
                return <div key={Utils.guid()}><a href={"/compensation?adjustmentId=" + adjustmentId}>View Adjustment</a></div>
              }) : ""}
            </div>
          },
          accessorKey: 'adjustments',
          header: 'Adjustments',
          enableEditing: false
        },
        
        {
          accessorFn: (row) => {
              return <div><a href="#" onClick={(event)=>{
                event.preventDefault();
                
                Utils.getData(
                  ('/api/employee/' + row.id + '/reviewees'), 
                  instance, 
                  account
                ).then((revieweesResult)=>{

                  openRevieweesModal(row, revieweesResult);
                
                });
                
                return false;
              }}>View Reviewees</a></div>
          },
          accessorKey: 'reviewees',
          header: 'Reviewees',
          enableEditing: false
        },        
        
        {
          accessorFn: (row) => {
              return <div><a href="#" onClick={(event)=>{
                event.preventDefault();
                // console.log("ROW", row);
                
                Utils.getData(
                  ('/api/employee/' + row.id + '/reviewers'), 
                  instance, 
                  account
                ).then((reviewersResult)=>{

                  openReviewersModal(row, reviewersResult);
                
                });
                
                return false;
              }}>View Reviewers</a></div>
          },
          accessorKey: 'reviewers',
          header: 'Reviewers',
          enableEditing: false
        },   
        //These are overrides, they are specifically for situations where we need a reviewer for someone higher on the report structure 
        {
          accessorKey: 'view_reviewers',
          header: 'Invidivual Reviewers',
          Cell: ({ cell, row }) => {            
            return <div>
              <a href="#" onClick={(event)=>{
                event.preventDefault();
                // console.log("cell", cell, "row", row);

                Utils.getData(
                  ('/api/employee/' + cell.row.original.id + '/reviewer'), 
                  instance, 
                  account
                ).then((jsonSelectedReviewers)=>{

                  openGlobalReviewerModal(cell.row.original, jsonSelectedReviewers);
                
                });

                return false;
              }}>Manage</a>
            </div>;
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        }, 
      ],
      [],
    );

    const requestManagerCompensation = () =>{
      // console.log("selectedEmployee", selectedEmployee);

      Utils.postData(
        "/api/employee_adjustment", 
        {
          employees : selectedEmployee,
          open_date : compensationOpenDate,
          effective_date: compensationEffectiveDate,
          deadline_date: compensationDeadlineDate,
          override_current_adjustments: overrideCurrentAdjustments
        },
        instance,
        account
      ).then((data) => {
        
        alert(data.message)
        closeCompensationModal();

      }).catch((error)=>{
        
        console.log("ERROR", error);
        alert("There was an error creating the requested compensation.");
        
      });
    
      return false;
    }    


    
    const addNewGlobalReviewer = ()=>{
      // console.log("SAVING reviewers", selectedAddEmployee);
      // console.log("selected Reviewers", selectedReviewers);

      const isReviewerAlreadySelected = selectedReviewers.find((reviewerItem)=>{
        return reviewerItem.reviewer_id == selectedAddEmployee;
      });
      // console.log("isReviewerAlreadySelected", isReviewerAlreadySelected);
      if(isReviewerAlreadySelected){
        alert("This reviewer has already been added for his employee.");
        return false;
      }

      if(!selectedAddEmployee){
        alert("Please select a employee from the dropdown list to continue.");
        return false;
      }

      Utils.putData(
        ('/api/employee/'+selectedReviewerEmployee.id+'/reviewer/'+selectedAddEmployee),
        {}, 
        instance, 
        account
      ).then((result)=>{

        Utils.getData(
          ('/api/employee/' + selectedReviewerEmployee.id + '/reviewer'), 
          instance, 
          account
        ).then((jsonSelectedReviewers)=>{

          setSelectedReviewers(jsonSelectedReviewers);
        
        });

      })

      return false;
    };

    
    const handleSelectEmployee = (option)=> {
      // console.log("option", option);
      const selectedReviewerEmployee = option.value
      setSelectedAddEmployee(selectedReviewerEmployee);
      return false;
    };

    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    return <div>
        <Modal
          isOpen={modalCompensationIsOpen}
          onAfterOpen={afterOpenCompensationModal}
          onRequestClose={closeCompensationModal}
          style={modalStyles}
          contentLabel="Request Adjustment"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Request Adjustment 
            for {selectedEmployee && selectedEmployee.length === 1 ? (Utils.decodeHTML(selectedEmployee[0].first_name) + " " + Utils.decodeHTML(selectedEmployee[0].last_name)) : "selected users"}
          </h2>
          <div className="modalBody">
            Open Date: 
            <Datetime 
                dateFormat="MM-DD-YYYY" 
                timeFormat={false}
                closeOnSelect={true} 
                onChange={e => {
                  // console.log(e, moment, typeof e);
                  let event;
                  if(typeof e === "string"){
                    event = moment(e);
                  }else{
                    event = e;
                  }
                  // console.log(event);
                  setCompensationOpenDate(event.format("MM-DD-YYYY"))
                }} 
            />

            <br />

            Effective Date: 
            <Datetime 
                dateFormat="MM-DD-YYYY" 
                timeFormat={false}
                closeOnSelect={true} 
                onChange={e => {
                  // console.log(e, moment, typeof e);
                  let event;
                  if(typeof e === "string"){
                    event = moment(e);
                  }else{
                    event = e;
                  }
                  // console.log(event);
                  setCompensationEffectiveDate(event.format("MM-DD-YYYY"))
                }} 
            />

            <br />
            Deadline Date: 
            <Datetime 
                dateFormat="MM-DD-YYYY" 
                timeFormat={false}
                closeOnSelect={true} 
                onChange={e => {
                  let event;
                  if(typeof e === "string"){
                    event = moment(e);
                  }else{
                    event = e;
                  }                  
                  // console.log(e, e.format("MM-DD-YYYY"));
                  setCompensationDeadlineDate(e.format("MM-DD-YYYY"))
                }} 
            />      

            <br />
            Override current open adjustments: 
            <input 
              type='checkbox' 
              checked={overrideCurrentAdjustments}
              id='overrideCurrentAdjustments' 
              name='overrideCurrentAdjustments' 
              onChange={e => {
                // console.log("e.target.value", e.target.checked, e.target);
                setOverrideCurrentAdjustments(e.target.checked)
              }} /><br />
          </div>
          <div className="modalFooter">
            <button className="btn btn-primary" onClick={()=>{
              // console.log("VALUES:", compensationEffectiveDate, overrideCurrentAdjustments);
              if(compensationEffectiveDate){
                requestManagerCompensation();
              }else{
                alert("Please enter an effective date for any requested adjustments before continuing.");
              }

            }}>OK</button>
            <button className="btn btn-primary" onClick={closeCompensationModal}>Cancel</button>
          </div>
        </Modal>

        <Modal
          isOpen={modalRevieweesIsOpen}
          onAfterOpen={afterOpenRevieweesModal}
          onRequestClose={closeRevieweesModal}
          style={modalStyles}
          contentLabel="Manage Reviewees"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Viewing Reviewees 
          </h2>
          <div className="modalBody employeeListRevieweesModalBody">
            {selectedReviewees ? selectedReviewees.map((revieweeItem)=>{
              return <div>{Utils.decodeHTML(revieweeItem.last_name)}, {Utils.decodeHTML(revieweeItem.first_name)}</div>
            }) : null}
          </div>
          <div className="modalFooter">
            <button className="btn btn-primary" onClick={closeRevieweesModal}>Close</button>
          </div>
        </Modal>
        
        <Modal
          isOpen={modalReviewersIsOpen}
          onAfterOpen={afterOpenReviewersModal}
          onRequestClose={closeReviewersModal}
          style={modalStyles}
          contentLabel="Manage Reviewees"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Viewing Reviewers for {Utils.decodeHTML(selectedRevieweeEmployee.first_name)} {Utils.decodeHTML(selectedRevieweeEmployee.last_name)}
          </h2>
          <div className="modalBody employeeListReviewersModalBody">
            {selectedReviewers ? selectedReviewers.map((reviewerItem)=>{
              return <div key={Utils.guid()}>{Utils.decodeHTML(reviewerItem.reviewer_last_name)}, {Utils.decodeHTML(reviewerItem.reviewer_first_name)}</div>
            }) : null}
          </div>
          <div className="modalFooter">
            <button className="btn btn-primary" onClick={closeReviewersModal}>Close</button>
          </div>
        </Modal>    
        

        <Modal
          isOpen={modalGlobalReviewerIsOpen}
          onAfterOpen={afterOpenGlobalReviewerModal}
          onRequestClose={closeGlobalReviewerModal}
          style={modalStyles}
          contentLabel="Manage Reviewers"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Manage Reviewers for {Utils.decodeHTML(selectedReviewerEmployee.first_name)} {Utils.decodeHTML(selectedReviewerEmployee.last_name)}
          </h2>
          <div className="modalBody">
            {
              selectedReviewers && selectedReviewers.length ? selectedReviewers.map((reviewerItem)=>{
                return <div key={Utils.guid()}>
                    <span className="reviewerLabel">{Utils.decodeHTML(reviewerItem.first_name)}</span>
                    <span className="reviewerLabel">{Utils.decodeHTML(reviewerItem.last_name)}</span>
                    <span className="reviewerLabel"><a href="#" onClick={(event)=>{
                      event.preventDefault();

                      Utils.deleteData(
                        "/api/employee/" + selectedReviewerEmployee.id + "/reviewer/" + reviewerItem.reviewer_id, 
                        instance, 
                        account
                      ).then((data) => {
                  
                        Utils.getData(
                          ('/api/employee/' + selectedReviewerEmployee.id + '/reviewer'), 
                          instance, 
                          account
                        ).then((jsonSelectedReviewers)=>{
                
                          setSelectedReviewers(jsonSelectedReviewers);
                        
                        });
                
                      });                    

                      return false;
                    }}>Remove</a></span>
                  </div>;
              }) : null
            }
            <div>&nbsp;</div>
            <div>
              <select name='employees_list' value={selectedAddEmployee} onChange={(event)=>{
                event.preventDefault();
                // console.log("event", event.target.value)
                handleSelectEmployee(event.target);
                return false;
              }}>
                <option key={Utils.guid()} value="0">-- None Selected --</option>
                {tableData ? tableData.map((employee_item)=>{
                  return <option key={Utils.guid()} value={employee_item.id}>{Utils.decodeHTML(employee_item.last_name)}, {Utils.decodeHTML(employee_item.first_name)}</option>
                }) : null}
              </select>&nbsp;
              <a href="#" onClick={(event)=>{
                event.preventDefault();
                
                addNewGlobalReviewer();

                return false;
              }}>Add As Reviewer</a>
            </div>
          </div>
          <div className="modalFooter">
            <button className="btn btn-primary" onClick={closeGlobalReviewerModal}>Close</button>
          </div>
        </Modal>        
                    

        <MaterialReactTable 
          columns={columns} 
          data={tableData}
          globalFilterFn="contains" 
          // enableStickyHeader
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => {
            const handleActivate = () => {
              const rowItems = [];
              table.getSelectedRowModel().flatRows.map((row) => {
                rowItems.push({
                  employee_id : row.original.id,
                  employee_hris_id : row.original.employee_hris_id,
                  first_name : row.original.first_name,
                  last_name : row.original.last_name
                });
              });
              // console.log(rowItems);

              setSelectedEmployee(rowItems);
              if(rowItems.length){
                openCompensationModal();              
              }else{
                alert("Please select at least 1 user to request compensation.");
              }
            };

            return <div>
              <span className="sectionTableHeader">Employee Listing |</span>
              &nbsp;&nbsp;&nbsp;        

              <button className="btn btn-primary" onClick={handleActivate}>
                Request compensation for selected employees
              </button>
             </div> 
          }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          editDisplayMode="row"
          // onEditingRowSave={handleSaveRow}        
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: tableData
          }}
        />
      </div>;
};

export default EmployeeList;