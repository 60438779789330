import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import jQuery from 'jquery';

import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';

import * as Utils from '../lib/utils';
import CONFIG from '../config';

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const modalBulkStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


// const data = [];
//nested data is ok, see accessorKeys in ColumnDef below  
const SalaryGroups = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });

    const storedYear = localStorage.getItem("salaryGroup-selectedYear");
    const [selectedYear, setSelectedYear] = useState(storedYear ? storedYear : (new Date()).getFullYear());
    
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalAction, setModalAction] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalSelectedSalaryGroup, setModalSelectedSalaryGroup] = useState(null);
    const [modalTitle, setModalTitle] = useState("");

    const [modalGroupYear, setModalGroupYear] = useState((new Date()).getFullYear());
    const [modalGroupDepartment, setModalGroupDepartment] = useState("");  
    const [modalGroupName, setModalGroupName] = useState("");
    const [modalGroupDescription, setModalGroupDescription] = useState("");  

    const [modalBulkIsOpen, setBulkIsOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    let subtitle;

    
    function openBulkModal() {
      setBulkIsOpen(true);
    }     
    function afterOpenBulkModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeBulkModal() {
      setBulkIsOpen(false);
    }


    function openModal(action, groupInfo) {
      setModalAction(action);
      setModalText(action, groupInfo);
      setIsOpen(true);
    } 
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
      // console.log("Modal Contents", jQuery(".modalContents"))
    }
    function closeModal() {
      setIsOpen(false);
    }
    
    function setModalText(action, groupInfo) {
      // console.log("ACTION", action, groupInfo);
    
      let header = "";
      let body = <div>
        <div>
          <TextField
            required
            fullWidth 
            id="groupYear"
            label="Group Year"
            variant="standard"
            defaultValue={groupInfo ? groupInfo.year : ""}
            onChange={evt => setModalGroupYear(evt.target.value)} 
          /><br /><br />

          <TextField
            required
            fullWidth 
            id="groupDepartment"
            label="Group Department"
            variant="standard"
            defaultValue={groupInfo ? groupInfo.group_department : ""}
            onChange={evt => setModalGroupDepartment(evt.target.value)} 
          /><br /><br />
          
          <TextField
            required
            fullWidth 
            id="groupName"
            label="Group Name"
            variant="standard"
            defaultValue={groupInfo ? groupInfo.group_name : ""}
            onChange={evt => setModalGroupName(evt.target.value)} 
          /><br /><br />
          
          <TextField
            required
            fullWidth 
            id="groupDescription"
            label="Group Description"
            variant="standard"
            defaultValue={groupInfo ? groupInfo.group_description : ""}
            onChange={evt => setModalGroupDescription(evt.target.value)} 
          /><br /><br />

        </div>
      </div>;

      if(action==="add"){
        header = "Add a salary group.";
      }else if(action==="edit"){
        header = "Edit salary group.";
      }else if(action==="delete"){
        header = "Delete salary group?";
      }
    
      setModalGroupYear(groupInfo ? groupInfo.year : "");
      setModalGroupDepartment(groupInfo ? groupInfo.group_department : "");
      setModalGroupName(groupInfo ? groupInfo.group_name : "");
      setModalGroupDescription(groupInfo? groupInfo.group_description : "");

      setModalSelectedSalaryGroup(groupInfo);
      setModalTitle(header);
      setModalBody(body);
    
      return false;
    }
    

    const fetchData = async () => {
      if (!tableData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const jsonResponse = await Utils.getData(
          ('/api/salary_range_group?year='+selectedYear), 
          instance, 
          account
        );
        
        setTableData(jsonResponse);
        setRowCount(jsonResponse.length);        

      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear]);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });

    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: 'delete_group',
          header: 'Delete Group',
          Cell: ({ cell, row }) => {
            return <div><a href="#" onClick={(event)=>{
              event.preventDefault();
              // console.log("EVENT", event, cell, row );
              if(window.confirm("Are you sure you would like to delete this group?")){
                deleteSalaryGroup(cell.row.original.id);
              }
              return false;
            }}>Delete</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        },        
        {
          accessorKey: 'view_locations',
          header: 'View Group Locations',
          Cell: ({ cell }) => {
            // console.log("cell, row", cell.row, cell)
            // console.log("cell.row.original.period_start", cell.row.original.period_start.substring(0,4))
            const theYear = cell.row.original.period_start.substring(0,4);
            return <div><a href={'/salaryGroupLocations?group_id=' + cell.row.getValue("id") + '&year=' + theYear}>View</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
        },        
        {
          accessorKey: 'view_ranges',
          header: 'View Group Ranges',
          Cell: ({ cell }) => {
            // console.log("cell, row", cell.row, cell)
            // console.log("cell.row.original.period_start", cell.row.original.period_start.substring(0,4))
            const theYear = cell.row.original.period_start.substring(0,4);
            return <div><a href={'/salaryGroupRanges?group_id=' + cell.row.getValue("id") + '&year=' + theYear}>View</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
        },
        {
          accessorKey: 'id',
          header: 'ID',
          enableEditing: false
        },
        {
          accessorKey: 'group_department',
          header: 'Group Department',
          enableEditing: true
        },
        {
          accessorKey: 'group_name',
          header: 'Group Name',
          enableEditing: true
        },
        {
          accessorKey: 'group_description',
          header: 'Group Description',
          enableEditing: true,
        },
      ],
      [],
    );


    //saves the group to the DB and updates the client view
    function saveSalaryGroup(action, group){

      // console.log("action", action, "group", group)

      //DO THE ACTION
      let method;
      let data;

      if(action === "add"){
        method="postData";
        data = {
          period_start : modalGroupYear + "-01-01",
          period_end : modalGroupYear + "-12-31",
          group_name: modalGroupName,
          group_department: modalGroupDepartment,
          group_description: modalGroupDescription
        };
      }

      if(action === "edit"){
        method="putData";
        data = {
          id: group.id,
          period_start : group.year + "-01-01",
          period_end : group.year + "-12-31",          
          group_name: group.group_name,
          group_department: group.group_department,
          group_description: group.group_description
        };
      }

      let localSalaryGroups = Utils.copy(tableData);

      // console.log("DATA", data, instance, account)

      let url = "/api/salary_range_group";
      if(action==="edit"){
        url = url + "/" + group.id;
      }

      // console.log("IS FETCHING")

      Utils[method](url, data, instance, account).then((data) => {

        // console.log("HAS FETCHED", data);
        
        //find the group in the local list, update as needed
        let rowMatched=false;
        for(var i=0;i<localSalaryGroups.length;i++){
          if(group && localSalaryGroups[i].id===group.id){
            if(action==="edit"){
              localSalaryGroups[i] = data;
            }
            rowMatched=true;
            break;
          }
        }

        //add a group to the local list
        if(!rowMatched){
          localSalaryGroups.push(data)
        }

        setTableData(localSalaryGroups);

        closeModal();

      });

      return false;
    }


    const deleteSalaryGroup = (groupId)=>{
      Utils.deleteData(
        "/api/salary_range_group/" + groupId, 
        instance, 
        account
      ).then((data) => {
        // console.log("data", data)
        alert(data.message);

        if(data.result === "SUCCESS"){
          fetchData();
        }
      });
    }


    const handleSaveRow = async ({ exitEditingMode, row, values, table }) => {
      // console.log(row, values, table);
      // let data = table.getState().tableData; //deprecated
      let data = tableData;
      data[row.index] = values;
      setTableData([...data]);
      saveSalaryGroup("edit", values);
      exitEditingMode();
    };
    

    const resolveSalaryGroups = (year) => {
      // console.log("Do it!");

      Utils.postData(
        "/api/resolve_salary_groups", 
        {
          year : year
        },
        instance,
        account
      ).then((result) => {
        alert(
          "Salary groups have been appled to adjustments in " + selectedYear + 
          ".  Please note, any missing job titles or locations will be left blank. " +   
          "Compensation requests without previous salary groups will show blank salary range min, mid, and maxxes."
        );
      });
      
      return false;
    }



    const uploadSalaryGroups = () => {
      // console.log("Do it!");
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      Utils.postFile(
        "/api/salary_range_group/upload", 
        formData,
        instance,
        account
      ).then((result) => {
        alert("Salary Groups Uploaded");
        closeBulkModal();
        fetchData();
      });
      return false;
    }    

    const tableHeightValue =  window.innerHeight > 187 ? window.innerHeight - 187 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    const thisYear = (new Date()).getFullYear(); 
    const yearsArray = [];
    for(let i=2022;i<=(thisYear+1);i++){
      yearsArray.push(i);
    }
    yearsArray.reverse();

    return <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Add Salary Group"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <div 
            className="modalContents"
            onKeyUp={(e) => { 
              // console.log("E", e);
              if (e.key === "Enter" && !e.defaultPrevented){
                saveSalaryGroup(modalAction, modalSelectedSalaryGroup);
              }
            }}
          >
            <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
              {modalTitle}
            </h2>
            <div className="modalBody">
              {modalBody}
            </div>
            <div className="modalFooter" style={{float:"right"}}>

              <Button onClick={closeModal}>
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button 
                variant="contained" 
                onClick={()=>{
                // console.log("PERFORM ACTION")
                saveSalaryGroup(modalAction, modalSelectedSalaryGroup);
              }}>
                Save
              </Button>

            </div>
          </div>
        </Modal>

        <Modal
          isOpen={modalBulkIsOpen}
          onAfterOpen={afterOpenBulkModal}
          onRequestClose={closeBulkModal}
          style={modalBulkStyles}
          contentLabel="Add Salary Group Range"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Bulk Upload
          </h2>
            <div className="modalBody">
              Please provide a CSV data file.

              <form>
                <input
                  type="file"
                  onChange={(e) => {
                    // console.log("e", e);
                    setSelectedFile(e.target.files[0])
                  }}
                />
              </form>

            </div>
            <div>&nbsp;</div>
            <div className="modalFooter">
              <button className="btn btn-primary" onClick={(event)=>{
                event.preventDefault();

                // console.log("SELECTED FILE", selectedFile);
                uploadSalaryGroups();

                return false;
              }}>OK</button>

              {/* <input type="submit" value="Upload File" class="btn btn-default" />             */}

              <button className="btn btn-primary" onClick={closeBulkModal}>Cancel</button>
            </div>
        </Modal>   

        <MaterialReactTable 
          columns={columns} 
          data={tableData}
          globalFilterFn="contains" 
          enableStickyHeader
          renderTopToolbarCustomActions={({ table }) => {
            return <div>
              <span className="sectionTableHeader">Salary Groups |</span>
              &nbsp;&nbsp;&nbsp;  

              Selected Year: <select 
              value={selectedYear}
              onChange={(event)=>{
                // console.log("event", event, event.target.value);
                localStorage.setItem("salaryGroup-selectedYear", event.target.value);
                setSelectedYear(event.target.value);
                return false;
              }}>
                {yearsArray.map((year)=>{
                  return <option key={Utils.guid()}>{year}</option>;
                })}
              </select>

              &nbsp;&nbsp;&nbsp;

              <button className="btn btn-primary"
                onClick={(event)=>{
                  openModal("add", {
                    year : (new Date()).getFullYear()
                  });
                  return false;
                }}
              >
                Add Salary Group
              </button>
              
              &nbsp;&nbsp;&nbsp;

              <button className="btn btn-primary"
                onClick={(event)=>{
                  // console.log("SHOW BULK UPLOADER", event);
                  openBulkModal();
                  return false;
                }}
              >
                Upload Salary Groups
              </button>

              &nbsp;&nbsp;&nbsp;

              <button className="btn btn-primary" onClick={()=>{
                if(window.confirm("This will attempt to resolve salary groups in adjustments falling in the year " + selectedYear)){
                  resolveSalaryGroups(selectedYear);
                }
              }}>
                Apply {selectedYear} Salary Groups in Adjustments
              </button>

              &nbsp;&nbsp;&nbsp;

              <a href={"/promotionAdvancement"}>Manage Job Advancement</a>
              &nbsp;&nbsp;&nbsp;
              <a href={"/location"}>Manage Locations</a>
            </div>;
          }}          
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          enableEditing
          onEditingRowSave={handleSaveRow}        
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: tableData,
            // columnVisibility: { group_description: false }
          }}
        />
      </div>;
};

export default SalaryGroups;